import React, { useEffect, useState } from "react";
import { MediaFinder } from "@microhelper/tiny-components";
import upload, { getMediaType } from "lib/upload";
import api from "api/media";
import { useToken } from "states/auth/hooks";
import { API_URL } from "config";

export interface Accepts {
  image?: string | false;
  video?: string | false;
  audio?: string | false;
}

interface Props {
  onSelect: (items: Array<Media & { mediaUrl: string }>) => void;
  onClose: () => void;
  accepts?: Accepts;
}

const MediaFinderContainer = ({ onSelect, onClose, accepts }: Props) => {
  const [items, setItems] = useState<Array<Media>>([]);

  const token = useToken();

  const getMediaUrl = ({ fileName }: Media) =>
    `${API_URL}/uploads/media/${fileName}`;

  const handleMediaUpload = async (files: FileList | null) => {
    const file = files?.[0];

    if (!file) return [];

    const res = await upload({
      uploadUrl: API_URL + "/api/upload",
      token,
      file,
      dir: "media",
    });

    const fileName = res?.data.file as string;

    const result = await api.create({
      entity: {
        type: getMediaType(file),
        fileName,
        dir: "media",
      },
      token,
    });

    if (result.success) setItems([...items, result.data]);

    return result.success ? [result.data._id] : [];
  };

  useEffect(() => {
    const run = async () => {
      const result = await api.getAll({});

      if (result.success) {
        setItems(result.data);
      }
    };

    run();
  }, []);

  return (
    <MediaFinder
      items={items}
      getMediaUrl={getMediaUrl}
      onClose={onClose}
      onSelect={(selectedIds) => {
        onSelect(
          items
            .filter((item) => selectedIds.includes(item._id))
            .map((item) => ({ ...item, mediaUrl: getMediaUrl(item) }))
        );
      }}
      onUpload={handleMediaUpload}
      onDelete={async (ids) => {
        ids.forEach((id) => {
          api.del({ token: "", id: `${id}` });
        });
        setItems(items.filter(({ _id }) => !ids.includes(_id)));
      }}
      accepts={accepts}
    />
  );
};

export default MediaFinderContainer;
