import { API_URL } from "config";

export const login = async (userName: string, password: string) => {
  try {
    const params = new URLSearchParams({
      grant_type: "password",
      userName,
      password,
    });

    const res = await fetch(new URL("/token", API_URL).href, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: params,
      referrerPolicy: "no-referrer",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
    });

    if (!res.ok) {
      let error;
      if (res.status === 401) error = "用户名或密码不正确";
      else if (res.status === 500) error = "无法连接服务器，请稍后重试";
      else error = `${res.status}: ${res.statusText}`;

      return { error };
    }

    const resJson = await res.json();

    return { data: resJson };
  } catch (err) {
    return { error: "无法连接服务器，请稍后重试" };
  }
};
