import React, { useState } from "react";
import clsx from "clsx";
import Uploader from "components/Uploader";
import styles from "./MediaPicker.module.scss";

interface Props {
  type: "media" | "image" | "video";
  value?: string;
  onChange: (value: string) => void;
  hint?: string;
}

/**
 * 输入媒体文件URL
 * 方式一：上传媒体文件，路径会自动生成
 * 方式二：直接手动输入URL路径
 */
const MediaPicker = ({ type, value, onChange, hint }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const config: {
    name: string;
    type: "image" | "video" | Array<"image" | "video">;
  } =
    type === "media"
      ? { name: "媒体", type: ["image", "video"] }
      : type === "image"
      ? { name: "图片", type: "image" }
      : { name: "视频", type: "video" };

  return (
    <div className={styles.picker}>
      <div className={styles.input}>
        <input
          value={value || ""}
          onChange={(e) => onChange(e.target.value)}
          placeholder={hint}
          // readOnly
          // disabled
        />

        <div
          className={clsx(styles.triangle, isOpen && styles["is-expanded"])}
          onClick={() => setIsOpen(!isOpen)}
        >
          {isOpen ? "关闭" : "打开"}
        </div>
      </div>
      {isOpen && (
        <div>
          <Uploader
            title={`将${config.name}文件拖动到此处，或点击选择文件`}
            type={config.type}
            dir="media"
            onLoad={(_file, fileUrl) => onChange(`/uploads/media/${fileUrl}`)}
          />
        </div>
      )}
    </div>
  );
};

export default MediaPicker;
