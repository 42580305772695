import React from "react";
import { Outlet } from "react-router";
import SideBarLayout from "components/SideBar/SideBarLayout";

const menus = [
  { text: "购买咨询", link: "purchase" },
  { text: "代理合作", link: "agency" },
  { text: "解决方案", link: "solution" },
];

const NotesLayout = () => {
  return (
    <SideBarLayout menus={menus}>
      <Outlet />
    </SideBarLayout>
  );
};

export default NotesLayout;
