import { useEffect, useMemo, useState } from "react";
import { Form, Field } from "react-final-form";
import DatePicker from "react-datepicker";
import QuillEditor from "lib/QuillEditor";
import MediaFinderContainer from "lib/MediaFinderContainer";
import TextBox from "components/TextBox";
import Uploader from "components/Uploader";
import FormRow from "components/MainDetails/FormRow";
import { API_URL } from "config";
// import { categoryMap } from "./common";
import styles from "./index.module.scss";

type FormValues = Article;

interface Props {
  id: string;
  item?: ArticleView;
  onSave: (item: Article) => Promise<any>;
}

const DetailsForm = ({ id, item, onSave }: Props) => {
  const [image, setImage] = useState<string>();
  const [video, setVideo] = useState<string>();

  const submit = async (values: FormValues) => {
    await onSave({
      ...values,
      image,
      video,
    });
  };

  const initialValues: Partial<FormValues> = useMemo(
    () => ({
      category: "news",
      locale: "zh",
      pinned: false,
      isPublished: false,
      ...item,
    }),
    [item]
  );

  useEffect(() => {
    if (item) {
      setImage(item.image);
      setVideo(item.video);
      return;
    }
    setImage("");
    setVideo("");
  }, [item]);

  return (
    <div className={styles.details}>
      <div className={styles.title}>{id ? "编辑" : "创建"}</div>

      <Form<FormValues>
        key={id}
        initialValues={initialValues}
        onSubmit={submit}
      >
        {({ handleSubmit, form, values, submitting }) => (
          <form className={styles.form} onSubmit={handleSubmit}>
            {id && <FormRow label="编号" control={id} />}

            <Field name="name" parse={(value) => value}>
              {({ input, meta }) => (
                <FormRow
                  label="名称"
                  control={<input {...input} type="text" placeholder="名称" />}
                  error={meta.touched && meta.error}
                />
              )}
            </Field>

            <Field name="locale" parse={(value) => value}>
              {({ input, meta }) => (
                <FormRow
                  label="语言"
                  control={
                    <select {...input}>
                      {Object.entries({ zh: "中文", en: "英文" }).map(
                        ([k, v]) => (
                          <option key={k} value={k}>
                            {v}
                          </option>
                        )
                      )}
                    </select>
                  }
                />
              )}
            </Field>

            {/* <Field name="category" parse={(value) => value}>
              {({ input, meta }) => (
                <FormRow
                  label="类别"
                  control={
                    <select {...input}>
                      <option />
                      {Object.entries(categoryMap).map(([k, v]) => (
                        <option key={k} value={k}>
                          {v}
                        </option>
                      ))}
                    </select>
                  }
                />
              )}
            </Field> */}

            <Field name="tags" parse={(value) => value}>
              {({ input, meta }) => (
                <FormRow
                  label="tags"
                  control={
                    <input {...input} type="text" placeholder="tags / 关键词" />
                  }
                  error={meta.touched && meta.error}
                />
              )}
            </Field>

            <Field name="author" parse={(value) => value}>
              {({ input, meta }) => (
                <FormRow
                  label="作者"
                  control={
                    <input
                      {...input}
                      type="text"
                      placeholder="显示的作者名称"
                    />
                  }
                  error={meta.touched && meta.error}
                />
              )}
            </Field>

            <FormRow
              label="缩略图"
              control={
                image && (
                  <div className={styles.col}>
                    <img
                      className={styles.thumbnail}
                      src={`${API_URL}/uploads/thumbnail/${image}`}
                      alt="thumbnail"
                    />
                    <div>{image}</div>
                  </div>
                )
              }
            />

            <FormRow
              label="上传图片"
              control={
                <Uploader
                  type="image"
                  dir="thumbnail"
                  onLoad={(_file, fileUrl) => setImage(fileUrl)}
                />
              }
            />

            {/* <FormRow
              label="视频"
              control={
                video && (
                  <div className={styles.col}>
                    <video controls loop className={styles.video}>
                      <source
                        src={`${API_URL}/uploads/thumbnail/${video}`}
                        type="video/mp4"
                      />
                    </video>
                    <div>{video}</div>
                  </div>
                )
              }
            />

            <FormRow
              label="上传视频"
              control={
                <Uploader
                  title="将视频文件拖动到此处，或点击选择文件"
                  type="video"
                  dir="thumbnail"
                  onLoad={(_file, fileUrl) => setVideo(fileUrl)}
                />
              }
            /> */}

            <Field name="summary" parse={(value) => value}>
              {({ input, meta }) => (
                <FormRow
                  label="简介"
                  control={<TextBox {...input} placeholder="简介" />}
                  error={meta.touched && meta.error}
                />
              )}
            </Field>

            <Field name="content" parse={(value) => value}>
              {({ input, meta }) => (
                <FormRow
                  label="内容"
                  control={
                    <QuillEditor
                      value={input.value}
                      onChange={input.onChange}
                      placeholder="请输入内容"
                      mediaPicker={({ onSelect, onClose }) => (
                        <MediaFinderContainer
                          onSelect={onSelect}
                          onClose={onClose}
                          accepts={{ video: "video/*", image: "image/*" }}
                        />
                      )}
                    />
                  }
                />
              )}
            </Field>

            <Field
              name="pinned"
              parse={(v) => v === "yes"}
              format={(v) => (v ? "yes" : "no")}
            >
              {({ input, meta }) => (
                <FormRow
                  label="置顶"
                  control={
                    <select {...input}>
                      <option value="no">未置顶</option>
                      <option value="yes">已置顶</option>
                    </select>
                  }
                  error={meta.touched && meta.error}
                />
              )}
            </Field>

            <Field name="isPublished" parse={(value) => value} type="checkbox">
              {({ input, meta }) => (
                <FormRow
                  label="状态"
                  control={
                    <label>
                      <input
                        {...input}
                        type="checkbox"
                        onChange={(e) => {
                          input.onChange(e);
                          // 当isPublished被勾中，并且publishedAt没有值的时候
                          // 设置一个默认的publishedAt值
                          if (!values.publishedAt && !values.isPublished)
                            form.change(
                              "publishedAt",
                              new Date().toISOString()
                            );
                        }}
                      />
                      发布
                    </label>
                  }
                  error={meta.touched && meta.error}
                />
              )}
            </Field>
            <Field name="publishedAt">
              {({ input, meta }) => (
                <FormRow
                  label="发布日期"
                  control={
                    <div className={styles["compact-control"]}>
                      <DatePicker
                        selected={
                          input.value &&
                          ["string", "number", "date"].includes(
                            typeof input.value
                          )
                            ? new Date(input.value)
                            : null
                        }
                        onChange={(date) => {
                          input.onChange({
                            target: {
                              value: date ? date.toISOString() : date,
                            },
                          });
                        }}
                        showTimeSelect
                        dateFormat="yyyy-MM-dd HH:mm"
                      />
                    </div>
                  }
                  error={meta.touched && meta.error}
                />
              )}
            </Field>

            <div className={styles.buttons}>
              <button color="secondary" onClick={() => form.reset()}>
                重置
              </button>
              <button color="primary" type="submit" disabled={submitting}>
                {submitting ? "提交中..." : "提交"}
              </button>
              {/* {hint && (
                    <span style={{ fontSize: "0.85em", color: "red" }}>{hint}</span>
                  )} */}
            </div>
          </form>
        )}
      </Form>
      {/* <pre>{JSON.stringify(currentItem, null, 2)}</pre> */}
    </div>
  );
};

export default DetailsForm;
