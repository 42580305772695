import { useParams } from "react-router";
import styles from "./index.module.scss";

interface Props {
  list: Array<Note>;
  onDelete: (id: string) => void;
}

const formatDate = (dateStr?: string) => {
  if (!dateStr) return "";

  const t = new Date(dateStr);

  return `${t.toLocaleDateString()} ${t.toLocaleTimeString()}`;
};

const List = ({ list, onDelete }: Props) => {
  const { type } = useParams();

  return (
    <div className={styles.container}>
      <table>
        <thead>
          <tr>
            <th>编号</th>
            <th>姓名</th>
            <th>手机</th>
            <th className={styles["align-left"]}>公司</th>
            <th className={styles["align-left"]}>邮箱</th>
            {type !== "solution" ? (
              <>
                <th className={styles["align-left"]}>行业</th>
                <th className={styles["align-left"]}>来源</th>
              </>
            ) : (
              <>
                <th className={styles["align-left"]}>场景</th>
                <th className={styles["note-th"]}>需求留言</th>
              </>
            )}

            <th>创建时间</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {list
            .filter((item) => item.type === type)
            .map((note, index) => (
              <tr key={index} style={{ margin: "1.5em" }}>
                <td>{index + 1}</td>
                <td>{note.name}</td>
                <td>{note.mobile}</td>
                <td>{note.company}</td>
                <td>{note.email}</td>
                {type !== "solution" ? (
                  <>
                    <td>{note.industry}</td>
                    <td>{note.source}</td>
                  </>
                ) : (
                  <>
                    <td>{note.scene}</td>
                    <td className={styles["note-td"]}>{note.note}</td>
                  </>
                )}
                <td>{formatDate(note.createdAt)}</td>
                <td>
                  <button
                    className={styles.button}
                    onClick={() => onDelete(note._id)}
                  >
                    删除
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default List;
