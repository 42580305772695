import React from "react";
import LeafEditor from "./LeafEditor";
import NodeEditor from "./NodeEditor";
import { isSchemaNode } from "./schema";
import styles from "./editor.module.scss";

/**
 * value 在运行时校验
 * array of array 暂不支持
 * schema array size 和 value array size可以不等
 */

interface Props {
  schema: SchemaArray;
  values: any;
  onChange: (values: Array<any>) => void;
  meta: { arrayIndex?: number; level: number };
}

const ArrayEditor = ({ schema, values, onChange, meta }: Props) => {
  // default value
  if (!values)
    values = schema.size ? new Array(schema.size).fill(undefined) : [];

  // validate type
  if (!Array.isArray(values)) {
    throw new Error("Error: value must be an array for ArrayEditor");
  }

  // use values size if schema size = 0
  const size = schema.size || values.length;

  const element = schema.element;
  const array = new Array(size).fill(0);

  const onIndexChange = (index: number, value: any) => {
    onChange([...values.slice(0, index), value, ...values.slice(index + 1)]);
  };

  const onIncrease = () => {
    onChange([...values, undefined]);
  };

  const onDecrease = () => {
    onChange(values.slice(0, -1));
  };

  return (
    <div>
      <div className={styles.header}>
        <h2>{schema.label}</h2>
        {schema.size === 0 && (
          <div className={styles.array}>
            <span>当前数量： {size}</span>
            <button className={styles.btn} onClick={onIncrease}>
              增加
            </button>
            {size !== 0 && (
              <button className={styles.btn} onClick={onDecrease}>
                删除
              </button>
            )}
          </div>
        )}
      </div>
      <div>
        {isSchemaNode(element)
          ? array.map((_, index) => (
              <NodeEditor
                key={index}
                schema={element}
                values={values[index]}
                onChange={(v) => onIndexChange(index, v)}
                meta={{ arrayIndex: index, level: meta.level + 1 }}
              />
            ))
          : array.map((_, index) => (
              <LeafEditor
                key={index}
                schema={element}
                value={values[index]}
                onChange={(v) => onIndexChange(index, v)}
                meta={{ arrayIndex: index, level: meta.level + 1 }}
              />
            ))}
      </div>
    </div>
  );
};

export default ArrayEditor;
