import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./index.module.scss";

interface Props {
  menus: Array<{ text: string; link: string }>;
}

const className = ({ isActive }: { isActive: boolean }) =>
  isActive ? styles["active-link"] : styles.link;

const SideBar = ({ menus }: Props) => {
  return (
    <div className={styles.sidebar}>
      <nav className={styles.nav}>
        {menus.map(({ text, link }, index) => (
          <NavLink key={index} className={className} to={link}>
            {text}
          </NavLink>
        ))}
      </nav>
    </div>
  );
};

export default SideBar;
