import axios from "axios";
import * as createApi from "@microhelper/tiny-client/dist/createAxiosApiClient";
import { API_URL } from "config";

const resource = "api/notes";

export const getAll = createApi.getAll<Note>(API_URL, resource, axios);
export const getOne = createApi.getOne<Note>(API_URL, resource, axios);
export const create = createApi.create<Note>(API_URL, resource, axios);
export const update = createApi.update<Note>(API_URL, resource, axios);
export const del = createApi.del<Note>(API_URL, resource, axios);

const client = {
  getAll,
  getOne,
  create,
  update,
  del,
};
export default client;
