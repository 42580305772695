import { useEffect, useMemo } from "react";
import Main from "components/MainDetails/Main";
import { useToken } from "states/auth/hooks";
import hooks from "states/article/hooks";
import { getAll, del } from "api/article";
// import { getCategoryName } from "./common";

interface Props {
  stateName: string;
}

const { useCurrentIdState, useMapValue, useGetAll, useDel } = hooks;

const compare = (objA: ArticleView, objB: ArticleView) => {
  if (objA.pinned === objB.pinned)
    return (objA.createdAt || "") > (objB.createdAt || "")
      ? 1
      : (objA.createdAt || "") < (objB.createdAt || "")
      ? -1
      : 0;

  return objA.pinned ? 1 : -1;
};

const List = ({ stateName }: Props) => {
  const [currentId, setCurrentId] = useCurrentIdState(stateName);
  const map = useMapValue(stateName);
  const sortedIds = useMemo(() => {
    return Object.values(map)
      .sort(compare)
      .map(({ _id }) => _id)
      .reverse();
  }, [map]);

  const getAllAction = useGetAll(stateName);
  const delAction = useDel(stateName);
  const token = useToken();

  const getTitle = (article?: Article) => (article ? article.name : "未命名");

  useEffect(() => {
    getAllAction(async () => {
      return await getAll({ token });
    });
  }, [getAllAction, token]);

  return (
    <Main
      title="新闻动态"
      itemIds={sortedIds}
      renderItem={(id) => <span>{getTitle(map[id])}</span>}
      onSelect={setCurrentId}
      onCreate={() => setCurrentId("")}
      onRemove={async (id) => {
        if (window.confirm("确认删除？")) {
          delAction(id, async () => {
            return await del({ id, token });
          });
        }
      }}
      currentId={currentId}
    />
  );
};

export default List;
