import {
  objectType,
  stringType,
  arrayType,
  textType,
  imageType,
  videoType,
} from "./schema";

const formFieldType = (label: string) =>
  objectType(label, {
    label: stringType("标签"),
    hint: stringType("提示"),
  });

export const menuSchema = objectType("菜单", {
  home: stringType("首页"),
  product: objectType("产品子菜单", {
    text: stringType("显示文字"),
    subs: objectType("列表", {
      t1: stringType("T1"),
      t3: stringType("T3"),
      "t3-plus": stringType("T3-Plus"),
    }),
  }),
  solution: stringType("解决方案"),
  technology: stringType("技术驱动"),
  news: stringType("新闻动态"),
  business: stringType("商务合作"),
  service: stringType("服务支持"),
  "about-us": stringType("关于我们"),
});

export const siteSchema = objectType("站点", {
  title: stringType("标题", "请输入站点标题"),
  keywords: stringType("关键词", "请输入网站关键词"),
  description: stringType("站点描述", "站点描述"),
  company: stringType("公司名称"),
  hotline: stringType("热线电话"),
  hotlineText: stringType("热线电话文字描述"),
  beian: stringType("网站备案号", "必须完整输入，否则可能引起备案风险"),
  anbei: stringType("公网安备号", "没有可以不输入"),
  social: arrayType(
    "社交媒体",
    0,
    objectType("social", {
      text: stringType("文字"),
      image: imageType("图片"),
      link: stringType("链接", "外部链接，扫码类可不填写"),
    })
  ),
  menus: menuSchema,
  learnMore: objectType("进一步了解", {
    title: stringType("标题文字"),
    subTitle: stringType("副标题文字"),
    solutionLabel: stringType("解决方案按钮文字"),
    purchaseLabel: stringType("购买咨询按钮文字"),
    image: imageType("图片"),
    mobileImage: imageType("移动端图片"),
  }),
  form: objectType("咨询表单", {
    title: stringType("标题"),
    subTitle: stringType("副标题"),
    typeLabel: stringType("表单类型标签"),
    types: objectType("表单类型", {
      purchase: stringType("购买咨询"),
      agency: stringType("代理合作"),
      solution: stringType("解决方案"),
    }),
    fields: objectType("表单字段", {
      name: formFieldType("姓名"),
      mobile: formFieldType("手机"),
      company: formFieldType("公司"),
      email: formFieldType("邮箱"),
      industry: formFieldType("行业"),
      source: formFieldType("来源"),
      scene: formFieldType("场景"),
      note: formFieldType("需求留言"),
    }),
    source: objectType("来源选项", {
      activity: stringType("活动/展会"),
      referer: stringType("同事/朋友推荐"),
      search: stringType("互联网搜索"),
      socialMedia: stringType("社交媒体"),
      other: stringType("其它"),
    }),
    industry: objectType("行业选项", {
      food: stringType("餐饮"),
      shoppingMall: stringType("购物中心"),
      hotel: stringType("酒店"),
      medical: stringType("医疗"),
      transportation: stringType("交通"),
      industry: stringType("工业"),
      other: stringType("其它"),
    }),
    scene: objectType("场景选项", {
      "office-building": stringType("写字楼"),
      "shopping-mall": stringType("商场"),
      industry: stringType("工业"),
      hospital: stringType("医院"),
      garage: stringType("车库"),
      hotel: stringType("酒店"),
      "transportation-hub": stringType("交通枢纽"),
      supermarket: stringType("商超"),
    }),
  }),
});

const homeProductType = (label: string) =>
  objectType(label, {
    link: stringType("链接"),
    image: imageType("图片"),
    mobileImage: imageType("移动端图片"),
    title: stringType("标题"),
    subTitle: stringType("副标题"),
    description: stringType("描述"),
    video: videoType("视频"),
    buttonText: stringType("按钮文字"),
  });

export const homeSchema = objectType("首页", {
  banner: objectType("Banner", {
    image: imageType("图片"),
    mobileImage: imageType("移动端图片"),
    title: textType("标题"),
    subTitle: stringType("副标题"),
    description: textType("描述"),
    buttonText: stringType("按钮文字"),
  }),
  products: objectType("产品", {
    p1: homeProductType("T1"),
    p2: homeProductType("T3"),
  }),
  tech: objectType("技术驱动", {
    title: stringType("标题"),
    subTitle: stringType("副标题"),
    description: textType("描述"), // text
    buttonText: stringType("按钮文字"),
    image: stringType("上图"),
    baseImage: stringType("底图"),
  }),
});

export const productSchema = (productName: string): SchemaNode =>
  objectType(productName, {
    features: objectType("特性", {
      title: textType("标题"),
      subTitle: stringType("副标题"),
      image: imageType("图片"),
      mobileImage: imageType("移动端图片"),
      items: arrayType(
        "特性区块",
        5,
        objectType("区块", {
          title: stringType("标题"),
          body: textType("内容"),
          icon: stringType("图标"),
        })
      ),
    }),
    video: objectType("视频", {
      title: textType("标题"),
      subTitle: stringType("副标题"),
      description: textType("描述"),
      video: videoType("视频"),
      posterImage: imageType("海报"),
      btnText: stringType("按钮文字"),
      workProcess: objectType("工作流程", {
        items: arrayType("", 6, stringType("文字")),
      }),
    }),
    specs: objectType("规格", {
      image: imageType("图片"),
      mobileImage: imageType("移动端图片"),
      title: stringType("标题"),
      subTitle: stringType("副标题"),
      items: arrayType(
        "规格内容",
        0,
        objectType("规格", {
          name: stringType("名称"),
          content: textType("内容"),
        })
      ),
    }),
  });

export const solutionSchema = objectType("解决方案", {
  banner: objectType("Banner", {
    title: stringType("标题"),
    subTitle: stringType("副标题"),
    image: imageType("图片"),
    mobileImage: imageType("移动端图片"),
  }),
  buttonText: stringType("按钮文字"),
  items: arrayType(
    "",
    7,
    objectType("场景", {
      name: stringType("名称"),
      title: stringType("标题"),
      subTitle: stringType("副标题"),
      image: imageType("图片"),
      mobileImage: imageType("移动端图片"),
      video: videoType("视频"),
      posterImage: imageType("海报"),
      painPoints: arrayType(
        "痛点",
        0,
        objectType("痛点", {
          title: stringType("标题"),
          icon: stringType("图标"),
        })
      ),
    })
  ),
  industryPainPoint: objectType("行业痛点", {
    title: stringType("标题"),
    subTitle: stringType("副标题"),
  }),
  solutionVideo: objectType("视频", {
    title: stringType("标题"),
    subTitle: stringType("副标题"),
  }),
  cleaningEfficiency: objectType("清洁效率", {
    title: stringType("标题"),
    subTitle: stringType("副标题"),
    items: arrayType(
      "",
      2,
      objectType("案例", {
        title: stringType("标题"),
        text1: stringType("文字1"),
        text2: stringType("文字2"),
        text3: stringType("文字3"),
        text4: stringType("文字4"),
        text5: stringType("文字5"),
        icon: stringType("图标1"),
        icon2: stringType("图标2", "可选填"),
      })
    ),
  }),
});

export const technologySchema = objectType("技术驱动", {
  banner: objectType("Banner", {
    title: stringType("标题"),
    subTitle: stringType("副标题"),
    image: imageType("图片"),
    mobileImage: imageType("移动端图片"),
  }),
  items: arrayType(
    "",
    7,
    objectType("区块", {
      icon: stringType("图标"),
      body: textType("内容"),
    })
  ),
  platform: objectType("平台", {
    title: stringType("标题"),
    subTitle: stringType("副标题"),
    items: arrayType(
      "",
      4,
      objectType("区块", {
        title: stringType("标题"),
        body: textType("内容"),
        icon: stringType("图标"),
      })
    ),
  }),
  algorithm: objectType("算法", {
    title: stringType("标题"),
    subTitle: stringType("副标题"),
    // highlights: arrayType("", 6, stringType("")),
    items: arrayType(
      "",
      5,
      objectType("区块", {
        title: textType("标题"),
        image: imageType("图片"),
      })
    ),
  }),
  hardware: objectType("硬件系统架构", {
    title: stringType("标题"),
    subTitle: stringType("副标题"),
    items: arrayType(
      "",
      4,
      objectType("区块", {
        title: stringType("标题"),
        icon: stringType("图标"),
      })
    ),
  }),
});

export const newsSchema = objectType("新闻动态", {
  title: stringType("标题", "请输入标题"),
  subTitle: stringType("副标题", "请输入副标题"),
  moreText: stringType("详情", "请输入文字"),
  loadMoreText: stringType("加载更多", "请输入文字"),
  noMoreText: stringType("没有更多了", "请输入文字"),
  prevText: stringType("上一篇", "请输入文字"),
  nextText: stringType("下一篇", "请输入文字"),
  relatedText: stringType("相关资讯", "请输入文字"),
  dateText: stringType("日期", "请输入文字"),
  authorText: stringType("作者", "请输入文字"),
  tagsText: stringType("tags", "请输入文字"),
});

export const businessSchema = objectType("商务合作", {
  banner: objectType("Banner", {
    title: stringType("标题"),
    subTitle: stringType("副标题"),
    image: imageType("图片"),
    mobileImage: imageType("移动端图片"),
  }),
});

export const serviceSchema = objectType("服务支持", {
  banner: objectType("Banner", {
    title: stringType("标题"),
    subTitle: stringType("副标题"),
    image: imageType("图片"),
    mobileImage: imageType("移动端图片"),
  }),
  items: arrayType(
    "",
    6,
    objectType("区块", {
      title: stringType("标题"),
      body: textType("内容"),
      icon: stringType("图标"),
    })
  ),
  map: objectType("网点", {
    title: stringType("标题"),
    description: textType("描述"),
    image: imageType("图片"),
    mobileImage: imageType("移动端图片"),
  }),
});

export const aboutUsSchema = objectType("服务支持", {
  banner: objectType("Banner", {
    title: stringType("标题"),
    subTitle: stringType("副标题"),
    image: imageType("图片"),
    mobileImage: imageType("移动端图片"),
  }),
  company: objectType("公司", {
    title: stringType("标题"),
    subTitle: stringType("副标题"),
    description: stringType("描述"),
    highlights: arrayType(
      "文字区块",
      5,
      objectType("区块", {
        title: stringType("标题"),
        description: stringType("描述"),
      })
    ),
  }),
  manufacture: objectType("制造", {
    title: stringType("标题"),
    subTitle: stringType("副标题"),
    images: arrayType("场景图", 4, imageType("图片")),
    features: arrayType(
      "图标区块",
      4,
      objectType("区块", {
        image: imageType("图片"),
        altImage: imageType("替换图片"),
        text: stringType("文字"),
      })
    ),
    highlights: arrayType(
      "文字区块",
      3,
      objectType("区块", {
        title: stringType("标题"),
        description: stringType("描述"),
      })
    ),
  }),
  joinUs: objectType("加入我们", {
    title: stringType("标题"),
    subTitle: stringType("副标题"),
    image: imageType("图片"),
    mobileImage: imageType("移动端图片"),
    btnText: stringType("按钮文字"),
    link: stringType("招聘链接"),
  }),
});

export const settingSchema: Record<
  string,
  SchemaNode
  // SchemaLeaf | SchemaNode | SchemaArray
> = {
  site: siteSchema,
  home: homeSchema,
  t1: productSchema("T1"),
  t3: productSchema("T3"),
  "t3-plus": productSchema("T3 Plus"),
  solution: solutionSchema,
  technology: technologySchema,
  news: newsSchema,
  business: businessSchema,
  service: serviceSchema,
  "about-us": aboutUsSchema,
};
