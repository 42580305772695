const config = {
  site: {
    title: "云象官网 - 洁出表现  云象呈现",
    keywords: "云象官网 清洁机器人",
    description: "云象官网 - 洁出表现，云象呈现",
    company: "杭州云象商用机器有限公司",
    hotline: "400-110-6778",
    hotlineText: "专家在线 即刻答疑",
    // zhihuLink: "https://www.zhihu.com",
    beian: "浙ICP备2021026262号-3",
    anbei: "",
    menus: {
      home: "首页",
      t1: "T1",
      t3: "T3",
      solution: "解决方案",
      technology: "技术驱动",
      business: "商务合作",
      service: "服务支持",
      "about-us": "关于我们",
    },
    learnMore: {
      title: "进一步了解",
      subTitle: "专家在线，即刻对接",
      solutionLabel: "解决方案",
      purchaseLabel: "购买咨询",
      image: "/images/service/bg.png",
      mobileImage: "/images/service/bg-m.png",
    },
    form: {
      title: "咨询",
      subTitle: "我们会尽快安排顾问专家与您联系",
      typeLabel: "咨询",
      types: {
        purchase: "购买咨询",
        agency: "代理合作",
        solution: "解决方案",
      },
      fields: {
        name: { label: "*姓名", hint: "请输入您的称呼" },
        mobile: { label: "*手机", hint: "请输入您的联系方式" },
        company: { label: "*公司", hint: "请输入您的公司名称" },
        email: { label: "邮箱", hint: "请输入您的邮箱" },
        industry: { label: "行业", hint: "您来自以下哪个行业？" },
        source: { label: "来源", hint: "您从何处了解到我们的产品？" },
        scene: { label: "场景", hint: "希望获取哪方面的方案？" },
        note: { label: "*需求留言", hint: "请描述您需要解决的问题" },
      },
      source: {
        activity: "活动/展会",
        referer: "同事/朋友推荐",
        search: "互联网搜索",
        socialMedia: "社交媒体",
        other: "其它",
      },
      industry: {
        food: "餐饮",
        shoppingMall: "购物中心",
        hotel: "酒店",
        medical: "医疗",
        transportation: "交通",
        industry: "工业",
        other: "其它",
      },
      scene: {
        "office-building": "写字楼",
        "shopping-mall": "商场",
        industry: "工业",
        hospital: "医院",
        garage: "车库",
        hotel: "酒店",
        "transportation-hub": "交通枢纽",
        supermarket: "商超",
      },
    },
  },
  home: {
    banner: {
      image: "/images/home/banner.png",
      mobileImage: "/images/home/banner-m.png",
      title: "“洁”出表现 云象呈现",
      subTitle: "INTELLIGENT SCRUBBER",
      description: [
        "国自孵化，专注清洁领域",
        "商用清洁机器人 . 创新服务 . 智能工具",
      ],
      buttonText: "在线专家咨询",
    },
    products: {
      t1: {
        image: "/images/home/t1.png",
        mobileImage: "/images/home/t1-m.png",
        title: "T1",
        subTitle: "洗地 . 扫地 . 吸尘 . 尘推",
        description: "云象清洁机器人",
        video: "/videos/t1.mp4",
        buttonText: "了解更多 >",
      },
      t3: {
        image: "/images/home/t3.png",
        mobileImage: "/images/home/t3-m.png",
        title: "T3",
        subTitle: "洗地 . 尘推 . 消杀",
        description: "云象清洁机器人",
        video: "/videos/t3.mp4",
        buttonText: "了解更多 >",
      },
    },
    tech: {
      title: "科技驱动",
      subTitle: "极致算力 / 毫米级定位 / 亿级数据处理",
      description: [
        "运行更可靠：强大芯片支撑、极致算力优化",
        "适应更聪明：动态预判避障、灵活速度控制",
        "巡检式清洁：3D全景覆盖、AI算法加持",
        "数字化运维：云端高效管理、支持定制接入",
      ],
      buttonText: "了解更多 >",
      baseImage: "/images/home/base.png",
      image: "/images/home/product.png",
      mobileImage: "/images/home/product-m.png",
    },
  },
  t1: {
    features: {
      title: "T1清洁机器人",
      subTitle: "室内小场景地面清洁专家",
      image: "/images/product/t1-bg.png",
      mobileImage: "/images/product/t1-bg-m.png",
      items: [
        {
          title: "小巧灵活",
          body: [
            "小巧更灵活，场景无限制",
            "可自动乘梯，自动过闸机",
            "多楼层作业，跨区域作业",
          ],
          icon: "/images/product/t1-f-1.svg",
        },
        {
          title: "一机多能",
          body: ["洗地+扫地+吸尘+尘推", "四大模块一键切换"],
          icon: "/images/product/t1-f-2.svg",
        },
        {
          title: "长续航",
          body: ["洗地4H，尘推10H", "7x24小时持续巡航工作"],
          icon: "/images/product/t1-f-3.svg",
        },
        {
          title: "真0贴边",
          body: [
            "边刷紧贴墙角 清扫不留遗漏",
            "任何死角都不放过，达到最高工作质量",
          ],
          icon: "/images/product/t1-f-4.svg",
        },
        {
          title: "便捷易用",
          body: [
            "10秒快拆，一键式操作",
            "垃圾盒、过滤盒、吸趴、尘推布等",
            "高维护频次部件，易取易清洗",
          ],
          icon: "/images/product/t1-f-5.svg",
        },
      ],
    },
    video: {
      title: ["T1清洁", "机器人"],
      subTitle: "室内小场景地面清洁专家",
      description: ["小巧灵活，", "复杂环境应对自如的清洁机器人"],
      video: "/videos/t1.mp4",
      posterImage: "/videos/t1.jpg",
      btnText: "购买咨询",
      workProcess: {
        items: [
          "远程踏勘",
          "智能建图",
          "清洁任务配置",
          "一键启动+自主作业",
          "自动返航充电",
          "远程运维",
        ],
      },
    },
    specs: {
      image: "/images/product/t1-spec.png",
      mobileImage: "/images/product/t1-spec-m.png",
      title: "产品参数",
      subTitle: "主要规格及性能优势",
      items: [
        { name: "外观尺寸", content: ["620mm(L) x 550mm(W) x 700mm(H)"] },
        { name: "运行噪音", content: ["低于68dB(A)"] },
        { name: "清洁效率", content: ["最高1200㎡/h"] },
        { name: "水箱容积", content: ["25L(清水箱12L，污水箱13L)"] },
        { name: "扫地宽度", content: ["510mm"] },
        { name: "洗地宽度", content: ["420mm"] },
        { name: "充电时间", content: ["3h"] },
        { name: "导航方式", content: ["激光导航"] },
        { name: "续航时长", content: ["洗地4h、尘推10h"] },
        { name: "安全防护", content: ["激光+视觉+超声+安全触边+防跌落"] },
        { name: "机身重量", content: ["65kg（空载）"] },
        { name: "清洗结构", content: ["滚刷x2 边刷x1"] },
        { name: "工作站", content: ["水电一体式、单电式"] },
        { name: "充电方式", content: ["自动充电、手动充电"] },
      ],
    },
  },
  t3: {
    features: {
      title: "T3清洁机器人",
      subTitle: "大型室内空间地面清洁专家",
      image: "/images/product/t3-bg.png",
      mobileImage: "/images/product/t3-bg-m.png",
      items: [
        {
          title: "一机多能",
          body: ["洗地+尘推+消杀，清洁功能模块化组合"],
          icon: "/images/product/t3-f-1.svg",
        },
        {
          title: "超长续航",
          body: ["洗地3.5H，尘推10H，自主充电换水，五级水循环过滤"],
          icon: "/images/product/t3-f-2.svg",
        },
        {
          title: "智能运行",
          body: ["断点续扫，绕障区补扫，支持定时任务，无人全自动转场"],
          icon: "/images/product/t3-f-3.svg",
        },
        {
          title: "安全可靠",
          body: ["3D激光+2D激光+视觉+超声+触边+防跌落等多传感器融合技术"],
          icon: "/images/product/t3-f-4.svg",
        },
        {
          title: "高效清洁",
          body: ["最高可达2000m²每小时"],
          icon: "/images/product/t3-f-5.svg",
        },
      ],
    },
    video: {
      title: ["T3清洁", "机器人"],
      subTitle: "大型室内空间地面专家",
      description: ["实用、好用、耐用的智能清洁机器人"],
      video: "/videos/t3.mp4",
      posterImage: "/videos/t3.jpg",
      btnText: "购买咨询",
      workProcess: {
        items: [
          "远程踏勘",
          "智能建图",
          "清洁任务配置",
          "一键启动+自主作业",
          "自动返航充电",
          "远程运维",
        ],
      },
    },
    specs: {
      image: "/images/product/t3-spec.png",
      mobileImage: "/images/product/t3-spec-m.png",
      title: "产品参数",
      subTitle: "主要规格及性能优势",
      items: [
        { name: "外观尺寸", content: ["850mm(L) x 650mm(W) x 1080(H)"] },
        { name: "导航方式", content: ["3D激光导航"] },
        {
          name: "安全防护",
          content: ["3D激光+2D激光+视觉+超声+安全触边+防跌落"],
        },
        { name: "机身重量", content: ["150kg（空载）"] },
        {
          name: "攀爬角度",
          content: ["小于8°(非工作状态下)，小于3°(工作状态下)"],
        },
        { name: "水箱容积", content: ["63L(清水箱35L，污水箱28L)"] },
        { name: "续航时长", content: ["洗地3.5h，尘推10h"] },
        { name: "运行噪音", content: ["低于70 dB(A)"] },
        { name: "充电时间", content: ["2h"] },
        { name: "电池类型", content: ["24V磷酸铁锂电池"] },
        { name: "消杀模块", content: ["雾化消杀、容积6L、雾化片寿命 ≥8000h"] },
        { name: "清洁效率", content: ["最高2000㎡/h"] },
        { name: "清洗宽度", content: ["500mm"] },
        { name: "吸水耙宽度", content: ["700mm"] },
      ],
    },
  },
  solution: {
    banner: {
      title: "解决方案",
      subTitle: "您的位置：首页 > 解决方案",
      image: "/images/solution/banner.png",
      mobileImage: "/images/solution/banner-m.png",
    },
    buttonText: "了解详情",
    items: [
      {
        name: "office-building",
        title: "写字楼智慧清洁解决方案",
        image: "/images/solution/office-building.png",
        mobileImage: "/images/solution/office-building-m.png",
        video: "/videos/office-building.mp4",
        posterImage: "/videos/office-building.jpg",
        subTitle:
          "写字楼保洁管理是企业管理中的主要内容，主要由地下车库、大厅、办公区域、活动区域、通道等场所构成。",
        painPoints: [
          {
            icon: "/images/solution/painpoint-1.svg",
            title: "人工清洁成本、培训成本不断提升",
          },
          {
            icon: "/images/solution/painpoint-2.svg",
            title: "人员管理困难且存在用工荒的难题",
          },
          {
            icon: "/images/solution/painpoint-3.svg",
            title: "楼层分散，清洁场景复杂多样，清洁覆盖率低",
          },
          {
            icon: "/images/solution/painpoint-4.svg",
            title:
              "采用人工清扫加拖地模式进行地面清洁作业，清洁面积大，人员少工作强度高",
          },
        ],
      },
      {
        name: "shopping-mall",
        title: "商场智慧清洁解决方案",
        image: "/images/solution/shopping-mall.png",
        mobileImage: "/images/solution/shopping-mall-m.png",
        video: "/videos/shopping-mall.mp4",
        posterImage: "/videos/shopping-mall.jpg",
        subTitle:
          "如今的消费者越来越注重购物的体验感和舒适度，任何一个细节都能影响到消费者的整体观感。一个安全、卫生、智能的环境能够在很大程度上愉悦消费者，从而增加消费意愿，提升客户忠诚度。主要用于地下车库和各楼层公共区域。",
        painPoints: [
          {
            icon: "/images/solution/painpoint-2.svg",
            title: "清洁人员招聘困难且管理成本高",
          },
          {
            icon: "/images/solution/painpoint-4.svg",
            title: "清洁作业规范难以管理",
          },
          {
            icon: "/images/solution/painpoint-8.svg",
            title: "清洁效果难以考核",
          },
          {
            icon: "/images/solution/painpoint-9.svg",
            title: "传统洗地设备与现代化商场不匹配",
          },
          {
            icon: "/images/solution/painpoint-6.svg",
            title: "商场人员密集，对疫情防控要求高",
          },
        ],
      },
      {
        name: "industry",
        title: "工业智慧清洁解决方案",
        image: "/images/solution/industry.png",
        mobileImage: "/images/solution/industry-m.png",
        video: "/videos/industry.mp4",
        posterImage: "/videos/industry.jpg",
        subTitle:
          "工业场景的清洁与产品的质量、企业的形象、客户的信任、生产的安全至关重要。然而工业场景中会产生噪音、污染物、有害气体、粉尘等都使得传统的保洁工作难以进行。",
        painPoints: [
          {
            icon: "/images/solution/painpoint-2.svg",
            title: "地点通常郊区较多，位置偏移招人困难",
          },
          {
            icon: "/images/solution/painpoint-10.svg",
            title: "面积大，人员工作强度高，人工作业管理难度大",
          },
          {
            icon: "/images/solution/painpoint-6.svg",
            title: "疫情防控需要尽量减少人员接触与流动",
          },
          {
            icon: "/images/solution/painpoint-11.svg",
            title: "难以与AGV等其它自动化设备交互协作",
          },
        ],
      },
      {
        name: "hospital",
        title: "医院智慧清洁解决方案",
        image: "/images/solution/hospital.png",
        mobileImage: "/images/solution/hospital-m.png",
        video: "/videos/hospital.mp4",
        posterImage: "/videos/hospital.jpg",
        subTitle:
          "医院的大厅、病房、走廊都在地面清洁的范围之内。楼层较多、人流量较大，清洁任务较为繁重。且受当今的疫情影响，消杀工作逐渐成为医院保洁任务中最重要的一环。",
        painPoints: [
          {
            icon: "/images/solution/painpoint-5.svg",
            title:
              "作为伤病员聚集地，公共区域广阔，人员流动频繁，卫生标准要求高",
          },
          {
            icon: "/images/solution/painpoint-6.svg",
            title: "人流量大，人员复杂繁多，交叉感染风险大",
          },
          {
            icon: "/images/solution/painpoint-4.svg",
            title: "保洁人员是感染的高发人群，日常消杀具有重要意义",
          },
          {
            icon: "/images/solution/painpoint-7.svg",
            title:
              "运维管理较难，白天有医生看病，需要晚上清洁，而晚上管理成本较高",
          },
        ],
      },
      {
        name: "garage",
        title: "地下车库智慧清洁解决方案",
        image: "/images/solution/garage.png",
        mobileImage: "/images/solution/garage-m.png",
        video: "/videos/garage.mp4",
        posterImage: "/videos/garage.jpg",
        subTitle:
          "地下车库往往容量很大，用来停放各种大小机动车辆的建筑物，主要由停车间、通道、坡道或机械提升间、出入口、调车场地等组成。",
        painPoints: [
          {
            icon: "/images/solution/painpoint-10.svg",
            title: "面积大，清洁需求广",
          },
          {
            icon: "/images/solution/painpoint-19.svg",
            title: "地面易产生油污",
          },
          {
            icon: "/images/solution/painpoint-20.svg",
            title: "车辆动态变化，障碍物多",
          },
        ],
      },
      // {
      //   name: "hotel",
      //   title: "酒店智慧清洁解决方案",
      //   image: "/images/solution/hotel.png",
      //   mobileImage: "/images/solution/hotel-m.png",
      //   video: "/videos/hotel.mp4",
      //   subTitle:
      //     "酒店的保洁工作不仅关系到酒店的形象，同时也顾及到客户的舒适度，是酒店长远发展的重要一环。一个安全、高效、智能的清洁环境是酒店增强客户黏性、培养客户忠诚度的有效助力。机器人适用酒店大堂、公共区域。",
      //   painPoints: [
      //     {
      //       icon: "/images/solution/painpoint-1.svg",
      //       title: "人工清洁成本、培训成本、管理成本高",
      //     },
      //     {
      //       icon: "/images/solution/painpoint-12.svg",
      //       title: "24小时高效清洁",
      //     },
      //     {
      //       icon: "/images/solution/painpoint-13.svg",
      //       title: "环境整洁干净，清洁标准高",
      //     },
      //     {
      //       icon: "/images/solution/painpoint-14.svg",
      //       title: "智慧化趋势",
      //     },
      //   ],
      // },
      {
        name: "transportation-hub",
        title: "交通枢纽智慧清洁解决方案",
        image: "/images/solution/transportation-hub.png",
        mobileImage: "/images/solution/transportation-hub-m.png",
        video: "/videos/transportation-hub.mp4",
        posterImage: "/videos/transportation-hub.jpg",
        subTitle:
          "现代综合交通运输体系日渐完善，飞机、地铁、火车等公共交通设施在各个省市覆盖越来越密，随之而来的是大型交通场景的清洁问题。",
        painPoints: [
          {
            icon: "/images/solution/painpoint-1.svg",
            title: "人工清洁成本、培训成本、管理成本高",
          },
          {
            icon: "/images/solution/painpoint-6.svg",
            title: "人流大、面积广，清洁频次高，污迹种类多",
          },
          {
            icon: "/images/solution/painpoint-15.svg",
            title: "人工作业管理难度大",
          },
        ],
      },
      {
        name: "supermarket",
        title: "商超智慧清洁解决方案",
        image: "/images/solution/supermarket.png",
        mobileImage: "/images/solution/supermarket-m.png",
        video: "/videos/supermarket.mp4",
        posterImage: "/videos/supermarket.jpg",
        subTitle:
          "标超、大型综合超市、便利店是现代连锁超市经营的主力业态，仍呈扩张状态。",
        painPoints: [
          {
            icon: "/images/solution/painpoint-2.svg",
            title: "人员招聘难",
          },
          {
            icon: "/images/solution/painpoint-16.svg",
            title: "通道狭窄，场景变化多",
          },
          {
            icon: "/images/solution/painpoint-17.svg",
            title: "清洁时间受限",
          },
          {
            icon: "/images/solution/painpoint-18.svg",
            title: "突发状况多，清洁效果不稳定",
          },
        ],
      },
    ],
    industryPainPoint: {
      title: "行业痛点",
      subTitle: "云象机器人提供高效解决方案",
    },
    solutionVideo: {
      title: "解决方案",
      subTitle: "成熟高效解决方案",
    },
    cleaningEfficiency: {
      title: "清洁效率及工时",
      subTitle: "10万平米，30天，要求符合清洁标准",
      items: [
        {
          icon: "/images/solution/cleaning-2.png",
          title: "人工操作",
          text1: "6万平方米，地面清洁",
          text2: "30人x8小时=",
          text3: "240个工时",
          text4: "4万平方米，其他清洁",
          text5: "10人x8小时=80个工时",
        },
        {
          icon: "/images/solution/cleaning-2.png",
          icon2: "/images/solution/zan-large.svg",
          title: "云象智能清洁解决方案",
          text1: "6万平方米，地面清洁机器人",
          text2: "10台x12小时=",
          text3: "120个工时",
          text4: "4万平方米，其他清洁",
          text5: "10人x8小时=80个工时",
        },
      ],
    },
  },
  technology: {
    banner: {
      title: "技术驱动",
      subTitle: "您的位置：首页 > 技术驱动",
      image: "/images/technology/banner.png",
      mobileImage: "/images/technology/banner-m.png",
    },
    items: [
      {
        body: ["强大芯片支撑", "极致算力优化", "运行稳定可靠"],
        icon: "/images/technology/compute.svg",
      },
      {
        body: ["全场景高效构图", "地图动态更新", "毫米级定位"],
        icon: "/images/technology/map.svg",
      },
      {
        body: ["3D全景覆盖", "智能感知", "防护无死角"],
        icon: "/images/technology/3d.svg",
      },
      {
        body: ["动态预判避障", "灵活速度控制", "动态路径规划"],
        icon: "/images/technology/security.svg",
      },
      {
        body: ["亿级数据积累", "AI算法加持", "场景适应智能化"],
        icon: "/images/technology/ground.svg",
      },
      {
        body: ["任务灵活设置", "一键启动", "任意位置启动任务"],
        icon: "/images/technology/task.svg",
      },
      {
        body: ["全流程数字化运维", "云端高效一体化", "支持定制化接入"],
        icon: "/images/technology/remote.svg",
      },
    ],
    platform: {
      title: "GRACE平台",
      subTitle: "移动机器人通用软硬件组态平台",
      items: [
        {
          title: "自研核心平台",
          body: ["自研核心平台", "硬件模块化设计", "软件灵活定制"],
          icon: "/images/technology/cloud.svg",
        },
        {
          title: "成熟稳定",
          body: [
            "平台历经四代优化升级、100+场景应用、3000+项目案例实施、10000+机器人部署、2.5亿行驶公里运行验证",
          ],
          icon: "/images/technology/triangle.svg",
        },
        {
          title: "灵活定制",
          body: ["快速响应产品定制需求，便捷构建各类产品与应用"],
          icon: "/images/technology/group.svg",
        },
        {
          title: "全生命周期管理",
          body: [
            "支撑全生命周期的平台体系，覆盖出厂、标定、实施、监控、运维全过程，采用一站式、高集成度、高自动化与智能化，全面提升产品总体运营能力",
          ],
          icon: "/images/technology/cycle.svg",
        },
      ],
    },
    algorithm: {
      title: "核心算法能力",
      subTitle: "高扩展性、易维护",
      items: [
        {
          title: ["多传感器融合", "超强感知能力"],
          image: "/images/technology/algorithm-1.png",
        },
        {
          title: ["全自动建图"],
          image: "/images/technology/algorithm-2.png",
        },
        {
          title: ["全场景快速自定位"],
          image: "/images/technology/algorithm-3.png",
        },
        {
          title: ["定位可靠", "适应性强"],
          image: "/images/technology/algorithm-4.png",
        },
        {
          title: ["动态更新适应环境变化"],
          image: "/images/technology/algorithm-5.png",
        },
      ],
    },
    hardware: {
      title: "硬件系统架构",
      subTitle: "高度集成 / 组件化管理",
      items: [
        {
          title: "核心平台专注清洁机器人",
          icon: "/images/technology/hardware-1.png",
        },
        {
          title: "高扩展性、易维护",
          icon: "/images/technology/hardware-2.png",
        },
        {
          title: "组件化管理",
          icon: "/images/technology/hardware-3.png",
        },
        {
          title: "灵活扩展",
          icon: "/images/technology/hardware-4.png",
        },
      ],
    },
  },
  business: {
    banner: {
      title: "商务合作",
      subTitle: "您的位置：首页 > 商务合作",
      image: "/images/solution/banner.png",
      mobileImage: "/images/solution/banner-m.png",
    },
  },
  service: {
    banner: {
      title: "服务支持",
      subTitle: "您的位置：首页 > 服务支持",
      image: "/images/service/banner.png",
      mobileImage: "/images/service/banner-m.png",
    },
    items: [
      {
        title: "移动端运维",
        body: ["小程序、公众号使用便捷", "告警和故障信息即时推送"],
        icon: "/images/service/mobile.svg",
      },
      {
        title: "多机器人管理",
        body: ["机器人状态监控", "任务报告查询"],
        icon: "/images/service/visual.svg",
      },
      {
        title: "远程运维",
        body: ["系统维护与升级", "在线远程问题分析", "及故障诊断"],
        icon: "/images/service/remote.svg",
      },
      {
        title: "1对1专人客户服务",
        body: ["7x24H在线支持", "2H响应", "48H现场服务"],
        icon: "/images/service/callout.svg",
      },
      {
        title: "备品备件",
        body: ["完备的保障体系", "保障客户合理储备"],
        icon: "/images/service/app.svg",
      },
      {
        title: "培训服务",
        body: ["完整全面的操作手册", "现场操作培训", "监督执行"],
        icon: "/images/service/book.svg",
      },
    ],
    map: {
      title: "全球服务网点分布",
      description: [
        "遍布全球几十个国家和地区，在全国范围内以及北美、欧洲和东南亚的部分国家和地区可提供现场实施，技术指导及使用培训。云运维平台远程实时守护，提供7×24小时技术支持。",
      ],
      image: "/images/service/map.png",
      mobileImage: "/images/service/map-m.png",
    },
  },
  "about-us": {
    banner: {
      title: "关于我们",
      subTitle: "您的位置：首页 > 关于我们",
      image: "/images/about-us/banner.png",
      mobileImage: "/images/service/banner-m.png",
    },
    company: {
      title: "商用清洁机器人",
      subTitle: "创新服务.智能工具",
      description:
        "杭州云象商用机器人有限公司是国自机器人孵化，致力于深研商用清洁场景，通过清洁机器人产品和创新服务，升级传统保洁作业模式，优化保洁用工，降低综合成本，提升保洁服务品质和智能化水平。目前广泛应用于交通枢纽、商业综合体、医院、工业等场景。",
      highlights: [
        // {
        //   title: "100+",
        //   description: "逾百种应用场景",
        // },
        // {
        //   title: "3000+",
        //   description: "三千多个项目案例",
        // },
        // {
        //   title: "10,000+",
        //   description: "出货数量过万台",
        // },
        // {
        //   title: "10,000,000+",
        //   description: "服务客户过千万天",
        // },
        // {
        //   title: "250,000,000+",
        //   description: "行驶里程超2.5亿公里",
        // },
        {
          title: "10+",
          description: "团队拥十多年移动机器人技术沉淀",
        },
        {
          title: "20+",
          description: "已服务和落地国家和地区",
        },
        {
          title: "150+",
          description: "已落地项目",
        },
        {
          title: "7000万m²",
          description: "清洗面积",
        },
      ],
    },
    manufacture: {
      title: "自主研发 智能制造",
      subTitle: "拥有规模完备的生产管理体系，ISO/CE/PSE认证",
      images: [
        "/images/about-us/manufacture-1.png",
        "/images/about-us/manufacture-2.png",
        "/images/about-us/manufacture-3.png",
        "/images/about-us/manufacture-4.png",
      ],
      features: [
        {
          image: "/images/about-us/chain.svg",
          altImage: "/images/about-us/chain-w.svg",
          text: "全球供应链",
        },
        {
          image: "/images/about-us/ai.svg",
          altImage: "/images/about-us/ai-w.svg",
          text: "智能制造",
        },
        {
          image: "/images/about-us/track.svg",
          altImage: "/images/about-us/track-w.svg",
          text: "质量追溯",
        },
        {
          image: "/images/about-us/inspect.svg",
          altImage: "/images/about-us/inspect-w.svg",
          text: "全品检测",
        },
      ],
      highlights: [
        {
          // title: "7000平米",
          title: "",
          description: "机器人制造车间",
        },
        {
          // title: "3条",
          title: "",
          description: "机器人专用产线",
        },
        {
          // title: "3400平米",
          title: "",
          description: "机器人测试场地",
        },
      ],
    },
    joinUs: {
      title: "加入我们",
      subTitle: "国自母公司 / 蓝海经济",
      image: "/images/about-us/bg.png",
      mobileImage: "/images/about-us/bg-m.png",
      btnText: "一键直聘  查看岗位 >",
      link: "https://www.hotjob.cn/wt/gzrobot/web/index/social",
    },
  },
};

export const configZh = config;

export const configEn = {
  site: {
    title: "Winner Business Machine - Outstanding Performance - Cleaning Robot",
    keywords: "Winner Business Machine Website Cleaning Robot",
    description:
      "Winner Business Machine - Outstanding Performance - Cleaning Robot",
    company: "Hangzhou Winner Business Machine Co., Ltd. ",
    hotline: "400-110-6778",
    hotlineText: "Experts Online",
    beian: "",
    anbei: "",
    menus: {
      home: "Home",
      t1: "T1",
      t3: "T3",
      solution: "Solutions",
      technology: "Technology",
      business: "Business",
      service: "Service",
      "about-us": "About Us",
    },
    learnMore: {
      title: "Learn More",
      subTitle: "Experts Online, Instant Contact",
      solutionLabel: "Solutions",
      purchaseLabel: "Purchase Consultation",
      image: "/images/service/bg.png",
      mobileImage: "/images/service/bg-m.png",
    },
    form: {
      title: "Consultation",
      subTitle:
        "We will arrange for a consultant to contact you as soon as possible.",
      typeLabel: "Consultation",
      types: {
        purchase: "Purchase Consultation",
        agency: "Agency Cooperation",
        solution: "Solutions",
      },
      fields: {
        name: { label: "*Name", hint: "Please enter your name" },
        mobile: { label: "*Mobile", hint: "Please enter your contact details" },
        company: { label: "*Company", hint: "Please enter your company name" },
        email: { label: "Email", hint: "Please enter your email address" },
        industry: {
          label: "Industry",
          hint: "Which of the following industries are you from?",
        },
        source: {
          label: "Source",
          hint: "Where did you find out about our products?",
        },
        scene: {
          label: "Scene",
          hint: "What kind of solution do you want to get?",
        },
        note: {
          label: "*Description",
          hint: "Please describe the problem you need to solve",
        },
      },
      source: {
        activity: "Event/Exhibition",
        referer: "Recommended by a colleague/friend",
        search: "Internet Search",
        socialMedia: "Social Media",
        other: "Other",
      },
      industry: {
        food: "CaterIng Industry",
        shoppingMall: "Shopping Center",
        hotel: "Hotel",
        medical: "Medical",
        transportation: "Transport",
        industry: "Industrial",
        other: "Other",
      },
      scene: {
        "office-building": "office building",
        "shopping-mall": "shopping mall",
        industry: "industry",
        hospital: "hospital",
        garage: "garage",
        hotel: "hotel",
        "transportation-hub": "transportation hub",
        supermarket: "supermarket",
      },
    },
  },
  home: {
    banner: {
      image: "/images/home/banner.png",
      mobileImage: "/images/home/banner-m.png",
      title: "OUTSTANDING PERFORMANCE",
      subTitle: "INTELLIGENT SCRUBBER",
      description: [
        "A subsidiary of Zhejiang Guozi Robotics. Focus on the cleaning sector",
        "Commercial cleaning robot . Intelligent tool . Innovative service",
      ],
      buttonText: "Online Expert Consultation",
    },
    products: {
      t1: {
        image: "/images/home/t1.png",
        mobileImage: "/images/home/t1-m.png",
        title: "T1",
        subTitle: "Mop . Sweep . Scrub . Vacuum",
        description: "Intelligent Cleaning Robot",
        video: "/videos/t1.mp4",
        buttonText: "More >",
      },
      t3: {
        image: "/images/home/t3.png",
        mobileImage: "/images/home/t3-m.png",
        title: "T3",
        subTitle: "Scrub . Mop . Disinfect",
        description: "Intelligent Cleaning Robot",
        video: "/videos/t3.mp4",
        buttonText: "More >",
      },
    },
    tech: {
      title: "Technology Driven",
      subTitle:
        "Extreme Computing Power / Millimeter-level Positioning / Billion-dollar Data Processing",
      description: [
        "More Reliable Operation: Powerful chip support, Extreme arithmetic optimization",
        "Smarter Adaptation: Dynamic pre-determined obstacle avoidance, Flexible speed control",
        "Inspection-based Cleaning: 3D panoramic coverage, AI algorithm support",
        "Digital Operation and Maintenance: Efficient management in the cloud, Support for customized access",
      ],
      buttonText: "More >",
      baseImage: "/images/home/base.png",
      image: "/images/home/product.png",
      mobileImage: "/images/home/product-m.png",
    },
  },
  t1: {
    features: {
      title: "T1 Intelligent Cleaning Robot",
      subTitle: "Indoor small scene floor cleaning expert",
      image: "/images/product/t1-bg.png",
      mobileImage: "/images/product/t1-bg-m.png",
      items: [
        {
          title: "Small and Flexible",
          body: [
            "Smaller and more flexible, Unlimited scenes",
            "Automatic stairway access, Automatic gate crossing",
            "Multi-floor operation, Cross-zone operation",
          ],
          icon: "/images/product/t1-f-1.svg",
        },
        {
          title: "One Machine with Multiple Functions",
          body: [
            "Floor washing + sweeping + vacuuming + dust pushing",
            "Four modules with one touch",
          ],
          icon: "/images/product/t1-f-2.svg",
        },
        {
          title: "Long Endurance",
          body: [
            "Floor washing 4 hours, Dust pushing 10 hours",
            "7x24 hours continuous cruising operation",
          ],
          icon: "/images/product/t1-f-3.svg",
        },
        {
          title: "0 Gap",
          body: [
            "Side brush cling to the corner, No omissions",
            "Any of the dead corners are not missed, Achieve the highest quality of work",
          ],
          icon: "/images/product/t1-f-4.svg",
        },
        {
          title: "Easy to Use",
          body: [
            "10s Dismantlement, One key operation",
            "Dust box, filter box, suction rack, duster cloth and other components",
            "With high maintenance frequency, Easy to take out and clean",
          ],
          icon: "/images/product/t1-f-5.svg",
        },
      ],
    },
    video: {
      title: ["T1 Intelligent", "Cleaning Robot"],
      subTitle: "Indoor small scene floor cleaning expert",
      description: [
        "Small and flexible,",
        "A cleaning robot capable of handling complex environments",
      ],
      video: "/videos/t1.mp4",
      posterImage: "/videos/t1.jpg",
      btnText: "Purchase Consultation",
      workProcess: {
        items: [
          "Remote survey",
          "Intelligent map building",
          "Clean task configuration",
          "One-touch start + Autonomous operation",
          "Automatic return charging",
          "Remote operation",
        ],
      },
    },
    specs: {
      image: "/images/product/t1-spec.png",
      mobileImage: "/images/product/t1-spec-m.png",
      title: "Product Parameters",
      subTitle: "Main specifications and performance advantages",
      items: [
        { name: "Size", content: ["620mm(L) x 550mm(W) x 700mm(H)"] },
        { name: "Operation Noise", content: ["< 68dB(A)"] },
        { name: "Cleaning Efficiency", content: ["Up to 1200m²/h"] },
        { name: "Water Tank", content: ["25L (clean 12L, dirty 13L)"] },
        { name: "Sweep Width", content: ["510mm"] },
        { name: "Mop Width", content: ["420mm"] },
        { name: "Charging Time", content: ["3h"] },
        { name: "Navigation Mode", content: ["Laser navigation"] },
        {
          name: "Battery Life",
          content: ["Floor washing 4 hours, Dust pushing 10 hours"],
        },
        {
          name: "Safety Protection",
          content: ["Laser + vision + ultrasound + edge-touch + anti-drop"],
        },
        { name: "Weight", content: ["65kg (unladen)"] },
        {
          name: "Cleaning Tool",
          content: ["Rolling brush x 2 Side brush x 1"],
        },
        {
          name: "Work Station",
          content: ["Water and Electricity Integrated, Single battery type"],
        },
        { name: "Charge", content: ["Automatic Charging + Manual Charging"] },
      ],
    },
  },
  t3: {
    features: {
      title: "T3 Intelligent Cleaning Robot",
      subTitle: "Large indoor space floor cleaning expert",
      image: "/images/product/t3-bg.png",
      mobileImage: "/images/product/t3-bg-m.png",
      items: [
        {
          title: "One Machine with Multiple Functions",
          body: [
            "Washing + dust pushing + disinfection and sterilization, Modular combination of cleaning functions",
          ],
          icon: "/images/product/t3-f-1.svg",
        },
        {
          title: "Ultra-long Endurance",
          body: [
            "Floor washing 3.5 hours, Dust pushing 10 hours, Self charging and water exchange, Five-stage water circulation and filtration",
          ],
          icon: "/images/product/t3-f-2.svg",
        },
        {
          title: "Intelligent Operation",
          body: [
            "Breakpoint continuous scanning, Fault area repair, Support scheduled tasks, Unmanned full-automatic transfer",
          ],
          icon: "/images/product/t3-f-3.svg",
        },
        {
          title: "Safe and Reliable",
          body: [
            "3D laser + 2D laser + vision + ultrasound + touchdown + anti-drop and other multi-sensor fusion technologies",
          ],
          icon: "/images/product/t3-f-4.svg",
        },
        {
          title: "Efficient Cleaning",
          body: ["Up to 2000m² Per hour"],
          icon: "/images/product/t3-f-5.svg",
        },
      ],
    },
    video: {
      title: ["T3 Intelligent", "Cleaning Robot"],
      subTitle: "Large indoor space floor cleaning expert",
      description: ["Practical, handy and durable intelligent cleaning robot"],
      video: "/videos/t3.mp4",
      posterImage: "/videos/t3.jpg",
      btnText: "Purchase Consultation",
      workProcess: {
        items: [
          "Remote survey",
          "Intelligent map building",
          "Clean task configuration",
          "One-touch start + Autonomous operation",
          "Automatic return charging",
          "Remote operation",
        ],
      },
    },
    specs: {
      image: "/images/product/t3-spec.png",
      mobileImage: "/images/product/t3-spec-m.png",
      title: "Product Parameters",
      subTitle: "Main specifications and performance advantages",
      items: [
        { name: "Size", content: ["850mm(L) x 650mm(W) x 1080(H)"] },
        { name: "Navigation Mode", content: ["3D Laser navigation"] },
        {
          name: "Safety Protection",
          content: [
            "3D laser + 2D laser + vision + ultrasound + edge-touch + anti-drop",
          ],
        },
        { name: "Weight", content: ["150kg (unladen)"] },
        {
          name: "Climbing Angle",
          content: [
            "<8° (in non-working condition), <3° (in working condition)",
          ],
        },
        { name: "Water Tank", content: ["63L (clean 35L, dirty 28L)"] },
        {
          name: "Battery Life",
          content: ["Floor washing 3.5 hours, Dust pushing 10 hours"],
        },
        { name: "Operation Noise", content: ["<70 dB(A)"] },
        { name: "Charging Time", content: ["2h"] },
        {
          name: "Battery Type",
          content: ["24V lithium iron phosphate battery"],
        },
        {
          name: "Disinfectant",
          content: [
            "Atomizing disinfection, Capacity: 6L, Atomizing piece service life≥8000h",
          ],
        },
        { name: "Cleaning Efficiency", content: ["Up to 2000㎡/h"] },
        { name: "Scrubbing Width", content: ["500mm"] },
        { name: "Mop Width", content: ["700mm"] },
      ],
    },
  },
  solution: {
    banner: {
      title: "Solutions",
      subTitle: "Your position: Home > Solutions",
      image: "/images/solution/banner.png",
      mobileImage: "/images/solution/banner-m.png",
    },
    buttonText: "Learn more",
    items: [
      {
        name: "office-building",
        title: "Intelligent Cleaning Solutions for Office Buildings",
        image: "/images/solution/office-building.png",
        mobileImage: "/images/solution/office-building-m.png",
        video: "/videos/office-building.mp4",
        posterImage: "/videos/office-building.jpg",
        subTitle:
          "Office building cleaning management is the main content of enterprise management, mainly composed of underground garages, halls, office areas, activity areas, passages and other places.",
        painPoints: [
          {
            icon: "/images/solution/painpoint-1.svg",
            title:
              "The cost of manual cleaning and training continues to increase",
          },
          {
            icon: "/images/solution/painpoint-2.svg",
            title: "Difficulty in personnel management and labor shortage",
          },
          {
            icon: "/images/solution/painpoint-3.svg",
            title:
              "The floors are scattered, the cleaning scenes are complex and diverse, and the cleaning coverage is low",
          },
          {
            icon: "/images/solution/painpoint-4.svg",
            title:
              "The manual cleaning and mopping mode for ground cleaning operations usually causes a large cleaning area and high work intensity with fewer personnel",
          },
        ],
      },
      {
        name: "shopping-mall",
        title: "Intelligent Cleaning Solutions for Shopping Centre",
        image: "/images/solution/shopping-mall.png",
        mobileImage: "/images/solution/shopping-mall-m.png",
        video: "/videos/shopping-mall.mp4",
        posterImage: "/videos/shopping-mall.jpg",
        subTitle:
          "Today's consumers pay more and more attention to the shopping experience and comfort, and any detail can affect the overall perception of consumers. A safe, hygienic, and smart environment can delight consumers to a great extent, thereby increasing consumption intentions and enhancing customer loyalty. It is mainly used in underground garages and public areas on each floor.",
        painPoints: [
          {
            icon: "/images/solution/painpoint-2.svg",
            title:
              "Difficult recruitment of cleaning staff and high management costs",
          },
          {
            icon: "/images/solution/painpoint-4.svg",
            title: "Cleaning practices are difficult to manage",
          },
          {
            icon: "/images/solution/painpoint-8.svg",
            title: "Cleaning effect is difficult to assess",
          },
          {
            icon: "/images/solution/painpoint-9.svg",
            title:
              "Traditional floor washing equipments do not match modern shopping malls",
          },
          {
            icon: "/images/solution/painpoint-6.svg",
            title:
              "Shopping malls are crowded with people and have high requirements for epidemic prevention and control",
          },
        ],
      },
      {
        name: "industry",
        title: "Intelligent Cleaning Solutions for Industry",
        image: "/images/solution/industry.png",
        mobileImage: "/images/solution/industry-m.png",
        video: "/videos/industry.mp4",
        posterImage: "/videos/industry.jpg",
        subTitle:
          "Cleanliness of industrial scenes is crucial to product quality, corporate image, customer trust, and production safety. However, noise, pollutants, harmful gases, dust, etc. will be generated in industrial scenes, which make traditional cleaning work difficult.",
        painPoints: [
          {
            icon: "/images/solution/painpoint-2.svg",
            title:
              "The location is usually in the suburbs, and it is difficult to recruit people because of location",
          },
          {
            icon: "/images/solution/painpoint-10.svg",
            title:
              "The area is large, the work intensity of personnel is high, and manual operation management is difficult",
          },
          {
            icon: "/images/solution/painpoint-6.svg",
            title:
              "Epidemic prevention and control needs to minimize personnel contact and movement",
          },
          {
            icon: "/images/solution/painpoint-11.svg",
            title:
              "Difficult to interact with other automation equipment such as AGV",
          },
        ],
      },
      {
        name: "hospital",
        title: "Intelligent Cleaning Solutions for Hospital",
        image: "/images/solution/hospital.png",
        mobileImage: "/images/solution/hospital-m.png",
        video: "/videos/hospital.mp4",
        posterImage: "/videos/hospital.jpg",
        subTitle:
          "Hospital halls, wards, and corridors are all within the scope of ground cleaning. There are many floors, the flow of people is large, and the cleaning task is relatively heavy. And affected by the current epidemic situation, disinfection work has gradually become the most important part of hospital cleaning tasks.",
        painPoints: [
          {
            icon: "/images/solution/painpoint-5.svg",
            title:
              "As a gathering place for the wounded and sick, the public area is vast, the flow of people is frequent, and the hygienic standards are high",
          },
          {
            icon: "/images/solution/painpoint-6.svg",
            title:
              "The flow of people is large, the personnel are complex and diverse, and the risk of cross-infection is high",
          },
          {
            icon: "/images/solution/painpoint-4.svg",
            title:
              "Cleaning staff is a high-risk group of infections, and daily disinfection is of great significance",
          },
          {
            icon: "/images/solution/painpoint-7.svg",
            title:
              "Operation management is difficult. There are doctors working during the day, and cleaning is required at night, and the management cost is higher at night",
          },
        ],
      },
      {
        name: "garage",
        title: "Intelligent Cleaning Solutions for Underground Parking Garage",
        image: "/images/solution/garage.png",
        mobileImage: "/images/solution/garage-m.png",
        video: "/videos/garage.mp4",
        posterImage: "/videos/garage.jpg",
        subTitle:
          "The underground garage often has a large capacity and is used to park various sizes of motor vehicles. It is mainly composed of parking rooms, passages, ramps or mechanical hoisting rooms, entrances and exits, and shunting yards.",
        painPoints: [
          {
            icon: "/images/solution/painpoint-10.svg",
            title: "Large area, wide range of cleaning needs",
          },
          {
            icon: "/images/solution/painpoint-19.svg",
            title: "The ground is prone to oily dirt",
          },
          {
            icon: "/images/solution/painpoint-20.svg",
            title: "Vehicle dynamic changes, many obstacles",
          },
        ],
      },
      {
        name: "transportation-hub",
        title: "Intelligent Cleaning Solutions for Transportation Hubs",
        image: "/images/solution/transportation-hub.png",
        mobileImage: "/images/solution/transportation-hub-m.png",
        video: "/videos/transportation-hub.mp4",
        posterImage: "/videos/transportation-hub.jpg",
        subTitle:
          "The modern comprehensive transportation system is becoming more and more perfect, and public transportation facilities such as airplanes, subways, and trains are covering more and more densely in various provinces and cities, followed by the problem of cleaning large-scale transportation scenes.",
        painPoints: [
          {
            icon: "/images/solution/painpoint-1.svg",
            title:
              "High manual cleaning costs, training costs, and management costs",
          },
          {
            icon: "/images/solution/painpoint-6.svg",
            title:
              "Large flow of people, large area, high frequency of cleaning, and many types of stains",
          },
          {
            icon: "/images/solution/painpoint-15.svg",
            title: "Manual operation management is difficult",
          },
        ],
      },
      {
        name: "supermarket",
        title: "Intelligent Cleaning Solutions for Supermarket",
        image: "/images/solution/supermarket.png",
        mobileImage: "/images/solution/supermarket-m.png",
        video: "/videos/supermarket.mp4",
        posterImage: "/videos/supermarket.jpg",
        subTitle:
          "Standard supermarkets, large general supermarkets, and convenience stores are the main business types of modern supermarket chains, and they are still in a state of expansion.",
        painPoints: [
          {
            icon: "/images/solution/painpoint-2.svg",
            title: "Recruitment is difficult",
          },
          {
            icon: "/images/solution/painpoint-16.svg",
            title: "The passage is narrow and the scene changes a lot",
          },
          {
            icon: "/images/solution/painpoint-17.svg",
            title: "Cleaning time is limited",
          },
          {
            icon: "/images/solution/painpoint-18.svg",
            title:
              "There are many emergencies and the cleaning effect is unstable",
          },
        ],
      },
    ],
    industryPainPoint: {
      title: "Industry Pain Points",
      subTitle: "Efficient Solutions Provided By Winner Business Machine",
    },
    solutionVideo: {
      title: "Solution",
      subTitle: "Mature and Efficient Solution",
    },
    cleaningEfficiency: {
      title: "清洁效率及工时",
      subTitle: "10万平米，30天，要求符合清洁标准",
      items: [
        {
          icon: "/images/solution/cleaning-2.png",
          title: "人工操作",
          text1: "6万平方米，地面清洁",
          text2: "30人x8小时=",
          text3: "240个工时",
          text4: "4万平方米，其他清洁",
          text5: "10人x8小时=80个工时",
        },
        {
          icon: "/images/solution/cleaning-2.png",
          icon2: "/images/solution/zan-large.svg",
          title: "云象智能清洁解决方案",
          text1: "6万平方米，地面清洁机器人",
          text2: "10台x12小时=",
          text3: "120个工时",
          text4: "4万平方米，其他清洁",
          text5: "10人x8小时=80个工时",
        },
      ],
    },
  },
  technology: {
    banner: {
      title: "Technology Driven",
      subTitle: "Your position: Home > Technology Driven",
      image: "/images/technology/banner.png",
      mobileImage: "/images/technology/banner-m.png",
    },
    items: [
      {
        body: [
          "Powerful chip support",
          "The ultimate in arithmetic optimization",
          "Stable and reliable operation",
        ],
        icon: "/images/technology/compute.svg",
      },
      {
        body: [
          "Efficient composition of the whole scene",
          "Dynamic map updates",
          "Millimeter-level positioning",
        ],
        icon: "/images/technology/map.svg",
      },
      {
        body: [
          "3D panoramic coverage",
          "Intelligent perception",
          "Protection without dead ends",
        ],
        icon: "/images/technology/3d.svg",
      },
      {
        body: [
          "Dynamic predictive obstacle avoidance",
          "Flexible speed control",
          "Dynamic Path Planning",
        ],
        icon: "/images/technology/security.svg",
      },
      {
        body: [
          "Billion-level data accumulation",
          "AI algorithm support",
          "Intelligent scene adaptation",
        ],
        icon: "/images/technology/ground.svg",
      },
      {
        body: [
          "Flexible task setting",
          "One touch start",
          "Start tasks anywhere",
        ],
        icon: "/images/technology/task.svg",
      },
      {
        body: [
          "Full Process Digital O&M",
          "Efficient integration in the cloud",
          "Support for customized access",
        ],
        icon: "/images/technology/remote.svg",
      },
    ],
    platform: {
      title: "GRACE Platform",
      subTitle: "Guozi Robot Advanced Configuration Environment",
      items: [
        {
          title: "Self-Developed Core Platform",
          body: [
            "Self-developed core platform",
            "Hardware modular design",
            "Flexible software customization",
          ],
          icon: "/images/technology/cloud.svg",
        },
        {
          title: "Mature And Stable",
          body: [
            "The platform has undergone four generations of optimization and upgrading, 100+ scenario applications, 3,000+ project cases, 10,000+ robot deployments, 250 million kilometers of operation verification",
          ],
          icon: "/images/technology/triangle.svg",
        },
        {
          title: "Flexible Customization",
          body: [
            "Quickly respond to product customization needs, Easily build a variety of products and applications",
          ],
          icon: "/images/technology/group.svg",
        },
        {
          title: "Lifecycle Management",
          body: [
            "A platform system that supports the entire life cycle, Covering the entire process of shipping, calibration, implementation, monitoring and operation and maintenance, One-stop, highly integrated, highly automated and intelligent, Enhances overall product operation capability",
          ],
          icon: "/images/technology/cycle.svg",
        },
      ],
    },
    algorithm: {
      title: "Core Algorithm Capability",
      subTitle: "Highly scalable, Easy to maintain",
      items: [
        {
          title: ["Multi-sensor fusion", "Superb sensing capabilities"],
          image: "/images/technology/algorithm-1.png",
        },
        {
          title: ["Fully automated map building"],
          image: "/images/technology/algorithm-2.png",
        },
        {
          title: ["Fast self-positioning for all scenes"],
          image: "/images/technology/algorithm-3.png",
        },
        {
          title: ["Reliable positioning", "Highly adaptable"],
          image: "/images/technology/algorithm-4.png",
        },
        {
          title: ["Dynamically updated to adapt to changes in the environment"],
          image: "/images/technology/algorithm-5.png",
        },
      ],
    },
    hardware: {
      title: "Hardware System Architecture",
      subTitle: "Highly Integrated / Component-based Management",
      items: [
        {
          title: "Core platform focused on cleaning robots",
          icon: "/images/technology/hardware-1.png",
        },
        {
          title: "Highly scalable, Easy to maintain",
          icon: "/images/technology/hardware-2.png",
        },
        {
          title: "Component-based management",
          icon: "/images/technology/hardware-3.png",
        },
        {
          title: "Flexible expansion",
          icon: "/images/technology/hardware-4.png",
        },
      ],
    },
  },
  business: {
    banner: {
      title: "Business Cooperation",
      subTitle: "Your position: Home > Business Cooperation",
      image: "/images/solution/banner.png",
      mobileImage: "/images/solution/banner-m.png",
    },
  },
  service: {
    banner: {
      title: "Service Support",
      subTitle: "Your position: Home > Service Support",
      image: "/images/service/banner.png",
      mobileImage: "/images/service/banner-m.png",
    },
    items: [
      {
        title: "Mobile O&M",
        body: [
          "Easy to use WeChat mini-programs and official accounts",
          "Alerts and fault messages are pushed instantly",
        ],
        icon: "/images/service/mobile.svg",
      },
      {
        title: "Multi-robot Management",
        body: ["Robot status monitoring", "Task report query"],
        icon: "/images/service/visual.svg",
      },
      {
        title: "Remote O&M",
        body: [
          "System maintenance and upgrades",
          "Online remote problem analysis",
          "and troubleshooting",
        ],
        icon: "/images/service/remote.svg",
      },
      {
        title: "1 to 1 Dedicated Customer Service",
        body: ["7x24H online support", "2H response", "48H on-site service"],
        icon: "/images/service/callout.svg",
      },
      {
        title: "Spare Parts",
        body: [
          "A complete guaranteed system",
          "A reasonable reserve for our customers",
        ],
        icon: "/images/service/app.svg",
      },
      {
        title: "Training Services",
        body: [
          "Complete and comprehensive operating manuals",
          "On-site operational training",
          "Supervised implementation",
        ],
        icon: "/images/service/book.svg",
      },
    ],
    map: {
      title: "Global Service Network",
      description: [
        "We are present in dozens of countries and regions around the world, and can provide on-site implementation, technical guidance and training in North America, Europe and some countries and regions in Southeast Asia. The cloud operation and maintenance platform are remotely guarded in real time and provides 7×24 technical support.",
      ],
      image: "/images/service/map-en.png",
      mobileImage: "/images/service/map-en-m.png",
    },
  },
  "about-us": {
    banner: {
      title: "About Us",
      subTitle: "Your position: Home > About Us",
      image: "/images/about-us/banner.png",
      mobileImage: "/images/service/banner-m.png",
    },
    company: {
      title: "Commercial Cleaning Robot",
      subTitle: "Innovative service . Intelligent tool",
      description:
        "Hangzhou Winner Business Machine Co., Ltd., a subsidiary of Zhejiang Guozi Robotics Co., Ltd., is committed to deeply studying the commercial cleaning scene, upgrading the traditional cleaning operation mode, optimizing cleaning labor, reducing comprehensive costs, and improving the cleaning service quality and intelligent level through cleaning robot products and innovative services. At present, it is widely used in transportation hubs, commercial complexes, hospitals, industrials and other spaces.",
      highlights: [
        // {
        //   title: "100+",
        //   description: "Over 100 application scenarios",
        // },
        // {
        //   title: "3000+",
        //   description: "Over three thousand project examples",
        // },
        // {
        //   title: "10,000+",
        //   description: "Over 10,000 units shipped",
        // },
        // {
        //   title: "10,000,000+",
        //   description: "Over 10 million days of customer service",
        // },
        // {
        //   title: "250,000,000+",
        //   description: "Over 250 million kilometers travelled",
        // },
        {
          title: "10+",
          description: "Mobile robot technology precipitation",
        },
        {
          title: "20+",
          description: "Countries and regions served and landed",
        },
        {
          title: "150+",
          description: "Landed projects",
        },
        {
          title: "70 million m²",
          description: "Cleaning area",
        },
      ],
    },
    manufacture: {
      title: "Self-developed Intelligent Manufacturing",
      subTitle:
        "Complete production management system with ISO/CE/PSE certification",
      images: [
        "/images/about-us/manufacture-1.png",
        "/images/about-us/manufacture-2.png",
        "/images/about-us/manufacture-3.png",
        "/images/about-us/manufacture-4.png",
      ],
      features: [
        {
          image: "/images/about-us/chain.svg",
          altImage: "/images/about-us/chain-w.svg",
          text: "Global Supply Chain",
        },
        {
          image: "/images/about-us/ai.svg",
          altImage: "/images/about-us/ai-w.svg",
          text: "Intelligent Manufacturing",
        },
        {
          image: "/images/about-us/track.svg",
          altImage: "/images/about-us/track-w.svg",
          text: "Quality Traceability",
        },
        {
          image: "/images/about-us/inspect.svg",
          altImage: "/images/about-us/inspect-w.svg",
          text: "Full Product Testing",
        },
      ],
      highlights: [
        {
          // title: "7000 m²",
          title: "",
          description: "Robotic manufacturing workshop",
        },
        {
          // title: "3 lines",
          title: "",
          description: "Robot-specific production lines",
        },
        {
          // title: "3400 m²",
          title: "",
          description: "Robot test site",
        },
      ],
    },
    joinUs: {
      title: "Join Us",
      subTitle: "Guozi Parent Company / Blue Ocean Economy",
      image: "/images/about-us/bg.png",
      mobileImage: "/images/about-us/bg-m.png",
      btnText: "One Click Direct Hire / View Jobs >",
      link: "https://www.hotjob.cn/wt/gzrobot/web/index/social",
    },
  },
};

export default config;
