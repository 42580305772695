import React, { useMemo, useRef, useState } from "react";
import ReactQuill, { Quill, ReactQuillProps } from "react-quill";
import BlotFormatter, { ImageSpec } from "quill-blot-formatter";
import VideoBlot from "./VideoBlot";
import VideoSpec from "./VideoSpec";
import "react-quill/dist/quill.snow.css";
import "./QuillEditor.scss";

Quill.register("modules/blotFormatter", BlotFormatter);
Quill.register(VideoBlot);

type MediaItem = Media & { mediaUrl: string };

type MediaPicker = (props: {
  onSelect: (items: Array<MediaItem>) => void;
  onClose: () => void;
}) => JSX.Element;

interface EditorProps extends ReactQuillProps {
  mediaPicker: MediaPicker;
}

const QuillEditor = ({ mediaPicker: MediaPicker, ...props }: EditorProps) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const quillRef = useRef<ReactQuill | null>(null);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          // ["undo", "redo"],
          [{ font: [] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike"],
          [{ color: [] }, { background: [] }],
          [{ script: "sub" }, { script: "super" }],
          ["blockquote", "code-block"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
          ["link", "image"],
          ["clean"],
        ],
        handlers: {
          image: () => setIsPickerOpen(true),
        },
      },
      blotFormatter: {
        specs: [
          ImageSpec,
          VideoSpec,
          // IframeVideoSpec,
        ],
      },
    }),
    []
  );

  const insertMedia = async (item: MediaItem) => {
    if (!quillRef.current) return;

    const editor = quillRef.current.getEditor();
    const range = editor.getSelection(true);
    const index = range?.index || 0;

    if (item.type === "video") {
      editor.insertEmbed(index, "video", {
        url: item.mediaUrl,
        controls: "",
        width: "100%",
        height: "100%",
      });

      return;
    }

    editor.insertEmbed(index, item.type, item.mediaUrl);
  };

  return (
    <div>
      <ReactQuill
        ref={(instance) => {
          quillRef.current = instance;
        }}
        theme="snow"
        modules={modules}
        {...props}
      />
      {isPickerOpen && (
        <MediaPicker
          onClose={() => setIsPickerOpen(false)}
          onSelect={(items) => {
            items.forEach((item) => insertMedia(item));
            setIsPickerOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default QuillEditor;
