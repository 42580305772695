import axios from "axios";
import * as createApi from "@microhelper/tiny-client/dist/createAxiosApiClient";
import { API_URL } from "config";

const resource = "api/media";

export const getAll = createApi.getAll<Media>(API_URL, resource, axios);
export const getOne = createApi.getOne<Media>(API_URL, resource, axios);
export const create = createApi.create<Media>(API_URL, resource, axios);
export const update = createApi.update<Media>(API_URL, resource, axios);
export const del = createApi.del<Media>(API_URL, resource, axios);

const client = {
  getAll,
  getOne,
  create,
  update,
  del,
};
export default client;
