import { createStateHooks } from "@microhelper/tiny-client/dist/createStateHooks";
import { currentIdState, currentItemState, mapState } from ".";

const hooks = createStateHooks<ArticleView>({
  getId: ({ _id }) => _id,
  currentIdState,
  currentItemState,
  mapState,
});

export default hooks;
