import React from "react";
import { Outlet, useParams } from "react-router";
import { menus } from "config";
import SideBarLayout from "components/SideBar/SideBarLayout";

const SettingsLayout = () => {
  const { locale } = useParams();

  return (
    <SideBarLayout
      menus={menus.map(({ name, text, link }) => ({
        text,
        link: `/settings/${locale}/${link || name}`,
      }))}
    >
      <Outlet />
    </SideBarLayout>
  );
};

export default SettingsLayout;
