import React from "react";
import { isSchemaArray, isSchemaNode } from "./schema";
import ArrayEditor from "./ArrayEditor";
import LeafEditor from "./LeafEditor";

interface Props {
  schema: SchemaNode;
  values: any;
  onChange: (values: Record<string, any>) => void;
  meta: { arrayIndex?: number; level: number };
}

const NodeEditor = ({ schema, values, onChange, meta }: Props) => {
  // default value
  if (typeof values === "undefined") values = {};
  // validate type
  if (!(typeof values === "object" && values !== null)) {
    throw new Error("Error: value must be an object and not null");
  }

  const onPropChange = (name: string, value: any) => {
    onChange({ ...values, [name]: value });
  };

  return (
    <div>
      <h2>
        {typeof meta.arrayIndex !== "undefined"
          ? `${schema.label}-${meta.arrayIndex + 1}`
          : schema.label}
      </h2>
      <div>
        {Object.entries(schema.props).map(([propName, prop], index) =>
          isSchemaNode(prop) ? (
            <NodeEditor
              key={index}
              schema={prop}
              values={values[propName]}
              onChange={(v) => onPropChange(propName, v)}
              meta={{ arrayIndex: undefined, level: meta.level + 1 }}
            />
          ) : isSchemaArray(prop) ? (
            <ArrayEditor
              key={index}
              schema={prop}
              values={values[propName]}
              onChange={(v) => onPropChange(propName, v)}
              meta={{ arrayIndex: undefined, level: meta.level + 1 }}
            />
          ) : (
            <LeafEditor
              key={index}
              schema={prop}
              value={values[propName]}
              onChange={(v) => onPropChange(propName, v)}
              meta={{ arrayIndex: undefined, level: meta.level + 1 }}
            />
          )
        )}
      </div>
    </div>
  );
};

export default NodeEditor;
