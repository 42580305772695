import React, { ReactNode } from "react";
import SideBar from "./SideBar";
import styles from "./index.module.scss";

interface Props {
  menus: Array<{ text: string; link: string }>;
  children?: ReactNode;
}

const Container = ({ menus, children }: Props) => {
  return (
    <div className={styles.container}>
      <SideBar menus={menus} />
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Container;
