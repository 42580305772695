import React from "react";
import { configEn, configZh } from "site";
import { upsert } from "api/config";
import { useToken } from "states/auth/hooks";
import styles from "./index.module.scss";

/**
 * 初始化配置
 */
const InitSettings = () => {
  const token = useToken();

  const init = () => {
    ["zh", "en"].forEach((locale) => {
      upsert({
        token,
        locale,
        config: {
          locale,
          config: JSON.stringify(locale === "zh" ? configZh : configEn),
        },
      });
    });
  };

  return (
    <div className={styles.section}>
      <div className={styles.header}>
        <div className={styles.title}>配置</div>
        <button className={styles.btn} onClick={init}>
          初始化配置
        </button>
      </div>
      <div>
        <h2>中文配置</h2>
        <pre>{JSON.stringify(configZh, null, 2)}</pre>
        <h2>英文配置</h2>
        <pre>{JSON.stringify(configEn, null, 2)}</pre>
      </div>
    </div>
  );
};

export default InitSettings;
