/**
 * 一些函数，帮助：
 *  创建类型描述
 *  判断类型
 */

export function isSchemaNode(
  schema: SchemaLeaf | SchemaNode | SchemaArray
): schema is SchemaNode {
  return (
    typeof schema === "object" && schema !== null && schema.type === "object"
  );
}

export function isSchemaArray(
  schema: SchemaLeaf | SchemaNode | SchemaArray
): schema is SchemaArray {
  return (
    typeof schema === "object" && schema !== null && schema.type === "array"
  );
}

export const stringType = (label: string, hint?: string): SchemaLeaf => ({
  type: "string",
  label,
  hint,
});

export const textType = (label: string): SchemaLeaf => ({
  type: "text",
  label,
});

export const mediaType = (label: string, hint?: string): SchemaLeaf => ({
  type: "media",
  label,
});

export const imageType = (label: string, hint?: string): SchemaLeaf => ({
  type: "image",
  label,
});

export const videoType = (label: string, hint?: string): SchemaLeaf => ({
  type: "video",
  label,
});

export const objectType = (
  label: string,
  props: SchemaNode["props"]
): SchemaNode => ({
  type: "object",
  label,
  props,
});

export const arrayType = (
  label: string,
  size: number,
  element: SchemaLeaf | SchemaNode
): SchemaArray => ({
  type: "array",
  label,
  size,
  element,
});
