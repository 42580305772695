import Quill from "quill";

const BlockEmbed = Quill.import("blots/block/embed");

class VideoBlot extends BlockEmbed {
  static create(value: {
    url: string;
    controls: string;
    width: string;
    height: string;
  }) {
    let node = super.create();
    node.setAttribute("src", value.url);
    node.setAttribute("controls", value.controls);
    node.setAttribute("width", value.width);
    node.setAttribute("height", value.height);
    node.setAttribute("webkit-playsinline", true);
    node.setAttribute("playsinline", true);
    node.setAttribute("x5-playsinline", true);

    // 设置最大宽度，高度自适应，video会自动保持长宽比
    node.setAttribute("style", "max-width: 100%; height: auto;");
    return node;
  }

  static value(node: any) {
    return {
      url: node.getAttribute("src"),
      controls: node.getAttribute("controls"),
      width: node.getAttribute("width"),
      height: node.getAttribute("height"),
    };
  }
}

VideoBlot.blotName = "video";
VideoBlot.tagName = "video";

export default VideoBlot;
