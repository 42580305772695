import { useToken } from "states/auth/hooks";
import hooks from "states/article/hooks";
import { create, update, getOne } from "api/article";
import DetailsForm from "./DetailsForm";

const stateName = "article";
const { useCurrentIdValue, useCurrentItemValue, useUpdate, useCreate } = hooks;

const Details = () => {
  const currentId = useCurrentIdValue(stateName);
  const currentItem = useCurrentItemValue(stateName);

  const token = useToken();
  const updateAction = useUpdate(stateName);
  const createAction = useCreate(stateName);

  const save = async (item: Article) => {
    if (currentId) {
      await updateAction(currentId, async () => {
        const result = await update({
          id: currentId,
          entity: item,
          token,
        });
        if (!result.success) return result;

        return await getOne({ id: currentId, token });
      });
    } else {
      await createAction(async () => {
        const result = await create({
          entity: item,
          token,
        });

        if (!result.success) return result;

        return await getOne({ id: result.data._id, token });
      });
    }
  };

  return <DetailsForm id={currentId} item={currentItem} onSave={save} />;
};

export default Details;
