import React from "react";
import { FormRow } from "@microhelper/tiny-components";
import MediaPicker from "./MediaPicker";

interface Props {
  schema: SchemaLeaf;
  value: string | Array<string>;
  onChange: (value: string | Array<string>) => void;
  meta: { arrayIndex?: number; level: number };
}

const LeafEditor = ({ schema, value, onChange, meta }: Props) => {
  // default value
  if (typeof value === "undefined") value = schema.type === "text" ? [] : "";

  // validate type
  if (
    (["string", "media", "image", "video"].includes(schema.type) &&
      typeof value !== "string") ||
    (schema.type === "text" && !Array.isArray(value))
  ) {
    console.error("types of schema and value not match", { schema, value });
    throw new Error("types of schema and value not match");
  }

  return (
    <FormRow
      label={
        typeof meta.arrayIndex !== "undefined"
          ? `${schema.label}-${meta.arrayIndex + 1}`
          : schema.label
      }
      control={
        schema.type === "string" ? (
          <input
            value={value || ""}
            onChange={(e) => onChange(e.target.value)}
            placeholder={schema.hint}
          />
        ) : schema.type === "text" ? (
          <textarea
            value={(value as Array<string>).join("\n") || ""}
            onChange={(e) => {
              const input = e.target.value;
              onChange(input.split("\n"));
            }}
            placeholder={schema.hint}
          />
        ) : (
          // media, image, video
          <MediaPicker
            type={schema.type}
            value={(value as string) || ""}
            hint={schema.hint}
            onChange={onChange}
          />
        )
      }
    />
  );
};

export default LeafEditor;
