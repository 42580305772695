import axios from "axios";
import * as createApi from "@microhelper/tiny-client/dist/createAxiosApiClient";
import { API_URL } from "config";

const resource = "api/admin/articles";

export const getAll = createApi.getAll<ArticleView>(API_URL, resource, axios);
export const getOne = createApi.getOne<ArticleView>(API_URL, resource, axios);
export const create = createApi.create<Article>(API_URL, resource, axios);
export const update = createApi.update<Article>(API_URL, resource, axios);
export const del = createApi.del<Article>(API_URL, resource, axios);

const client = {
  getAll,
  getOne,
  create,
  update,
  del,
};
export default client;
