import { useEffect } from "react";
import { useToken } from "states/auth/hooks";
import hooks from "states/note/hooks";
import { getAll, del } from "api/note";
import List from "./List";
import styles from "./index.module.scss";

const { useMapValue, useGetAll, useDel } = hooks;

const stateName = "notes";
const Notes = () => {
  const map = useMapValue(stateName);
  const list = Object.values(map);
  const getAllAction = useGetAll(stateName);
  const delAction = useDel(stateName);
  const token = useToken();

  const onDelete = async (id: string) => {
    if (window.confirm("确认删除？")) {
      delAction(id, async () => {
        return await del({ id, token });
      });
    }
  };

  useEffect(() => {
    getAllAction(async () => {
      return await getAll({ token });
    });
  }, [getAllAction, token]);

  return list?.length ? (
    <List list={list} onDelete={onDelete} />
  ) : (
    <div className={styles.empty}>暂无数据</div>
  );
};

export default Notes;
