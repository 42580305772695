import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getOne, upsert } from "api/config";
import { useToken } from "states/auth/hooks";
import { isSchemaArray, isSchemaNode } from "./schema";
import { settingSchema } from "./settingsSchema";
import ShowError from "./ShowError";
import NodeEditor from "./NodeEditor";
import LeafEditor from "./LeafEditor";
import styles from "./index.module.scss";

/**
 * 编辑 服务端 config
 * name 为 config 中的 key
 */
const Settings = () => {
  const params = useParams();
  const { name } = params;
  const locale = params.locale || "zh";

  const token = useToken();
  const [values, setValues] = useState<Record<string, any>>();
  const [error, setError] = useState("");

  const submit = async () => {
    if (typeof values !== "undefined") {
      await upsert({
        token,
        locale,
        config: { locale, config: JSON.stringify(values) },
      });
    }
  };

  useEffect(() => {
    getOne({ locale }).then((result) => {
      if (!result.success) {
        setError("加载配置失败");
        return;
      }

      setValues(JSON.parse(result.data?.config || "{}"));
    });
  }, [locale]);

  if (!name) return <ShowError title="没有提供要配置的项目" />;
  const section = settingSchema[name];

  if (!section || isSchemaArray(section))
    return <ShowError title={`不支持编辑配置: ${name}`} />;

  if (error) return <ShowError title={error} />;
  if (!values) return <ShowError title="正在加载。。。" />;

  return (
    <div className={styles.section}>
      <div className={styles.header}>
        <div className={styles.title}>
          配置
          <span className={styles.info}>{section.label}</span>
        </div>
        <button className={styles.btn} onClick={submit}>
          保存修改
        </button>
      </div>
      {isSchemaNode(section) ? (
        <NodeEditor
          schema={section}
          values={values[name]}
          onChange={(v) => {
            setValues({ ...values, [name]: v });
          }}
          meta={{ level: 0 }}
        />
      ) : (
        <LeafEditor
          schema={section}
          value={values[name]}
          onChange={(v) => {
            setValues({ ...values, [name]: v });
          }}
          meta={{ level: 0 }}
        />
      )}
    </div>
  );
};

export default Settings;
