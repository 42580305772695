import { NavLink } from "react-router-dom";
import { useLogout, useUserName } from "states/auth/hooks";
import styles from "./index.module.scss";

const linkClassName = ({ isActive }: { isActive: boolean }) =>
  isActive ? styles["active-link"] : styles.link;

const Navbar = () => {
  const user = useUserName();
  const logout = useLogout();

  return (
    <header className={styles.header}>
      <div>
        <img className={styles.logo} src="/logo.svg" alt="logo" />
      </div>
      <nav className={styles.nav}>
        {process.env.NODE_ENV !== "production" && (
          <NavLink className={linkClassName} to="/init-settings">
            初始化配置
          </NavLink>
        )}
        <NavLink className={linkClassName} to="/settings/zh">
          中文配置
        </NavLink>
        <NavLink className={linkClassName} to="/settings/en">
          英文配置
        </NavLink>
        <NavLink className={linkClassName} to="/notes">
          咨询表单
        </NavLink>
        <NavLink className={linkClassName} to="/news">
          新闻动态
        </NavLink>
      </nav>
      <div className={styles.member}>
        <span>欢迎您, {user}</span>
        <button className={styles.logout} onClick={logout}>
          退出
        </button>
      </div>
    </header>
  );
};

export default Navbar;
