import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { RecoilRoot } from "recoil";
import DefaultLayout from "components/Layout/DefaultLayout";
import Login from "components/Login";
import Notes from "components/Notes";
import Article from "components/Article";
import Settings from "components/Settings";
import SettingsLayout from "components/Layout/SettingsLayout";
import NotesLayout from "components/Layout/NotesLayout";
import InitSettings from "components/Settings/InitSettings";

function App() {
  return (
    <RecoilRoot>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<DefaultLayout />}>
            <Route index element={<Navigate to="notes" replace />} />
            <Route path="notes" element={<NotesLayout />}>
              <Route index element={<Navigate to="purchase" replace />} />/
              <Route path=":type" element={<Notes />} />
            </Route>
            <Route path="news" element={<Article />} />
            <Route path="settings/:locale" element={<SettingsLayout />}>
              <Route index element={<Navigate to="site" replace />} />
              <Route path=":name" element={<Settings />} />
            </Route>
            <Route path="init-settings" element={<InitSettings />}></Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </RecoilRoot>
  );
}

export default App;
