import axios, { AxiosProgressEvent } from "axios";
import { formData } from "@microhelper/tiny-client/dist/formData";

export const getMediaType = (file?: File) =>
  file?.type.includes("image")
    ? "image"
    : file?.type.includes("video")
    ? "video"
    : file?.type.includes("audio")
    ? "audio"
    : "unknown";

interface UploadParams {
  uploadUrl: string;
  token?: string;
  // file?: File;
  file: File;
  // type: "image" | "video";
  dir: "thumbnail" | "media" | string;
  // onLoad: (xhr: XMLHttpRequest, e: ProgressEvent<EventTarget>) => any;
  // onProgress?: (xhr: XMLHttpRequest, e: ProgressEvent<EventTarget>) => any;
  onProgress?: (progressEvent: AxiosProgressEvent) => void;
}

/** 上传一个文件到指定url */
const upload = async ({
  uploadUrl,
  token,
  file,
  // type,
  dir,
  // onLoad,
  onProgress,
}: UploadParams) => {
  // if (!file) return;

  const data = formData({
    file, //type,
    dir,
  });

  const headers = token ? { Authorization: `Bearer ${token}` } : {};

  return await axios.request({
    url: uploadUrl,
    method: "POST",
    headers,
    data,
    onUploadProgress: onProgress,
  });
};

export default upload;
