import React from "react";
import styles from "./index.module.scss";

interface Props {
  title: string;
  content?: string;
}

const ShowError = ({ title, content }: Props) => {
  return (
    <div className={styles.section}>
      <div className={styles.error}>
        <div className={styles.title}>{title}</div>
        {content && <div className={styles.content}>{content}</div>}
      </div>
    </div>
  );
};

export default ShowError;
