import axios from "axios";
import {
  Result,
  handleAxiosRequest,
} from "@microhelper/tiny-client/dist/request";
import { API_URL } from "config";

interface Config {
  locale: string;
  config: string;
}

const resource = "api/configs";

export const getOne = async ({
  locale,
}: {
  locale: string;
}): Promise<Result<Config>> => {
  return handleAxiosRequest(async () => {
    const headers: Record<string, string> = {};

    return axios.request({
      baseURL: API_URL,
      url: `${resource}/${locale}`,
      method: "GET",
      headers,
    });
  });
};

export const upsert = async ({
  token,
  locale,
  config,
}: {
  token: string;
  locale: string;
  config: Config;
}): Promise<Result<Config>> => {
  return handleAxiosRequest(async () => {
    const headers: Record<string, string> = {
      "Content-Type": "application/json",
    };
    if (token) headers["Authorization"] = `Bearer ${token}`;

    return axios.request({
      baseURL: API_URL,
      url: `${resource}/${locale}`,
      method: "POST",
      headers,

      data: config,
    });
  });
};
