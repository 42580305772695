export const API_URL = process.env.REACT_APP_API_URL || "";

export const menus = [
  {
    name: "site",
    text: "站点信息",
  },
  {
    name: "home",
    text: "首页",
    link: "",
  },
  {
    name: "t1",
    text: "T1",
  },
  {
    name: "t3",
    text: "T3",
  },
  {
    name: "t3-plus",
    text: "T3 Plus",
  },
  {
    name: "solution",
    text: "解决方案",
  },
  {
    name: "technology",
    text: "技术驱动",
  },
  {
    name: "news",
    text: "新闻动态",
  },
  {
    name: "business",
    text: "商务合作",
  },
  {
    name: "service",
    text: "服务支持",
  },
  {
    name: "about-us",
    text: "关于我们",
  },
];
