import BlotFormatter, { BlotSpec } from "quill-blot-formatter";

export default class VideoSpec extends BlotSpec {
  element: HTMLElement | null;

  constructor(formatter: BlotFormatter) {
    super(formatter);
    this.element = null;
  }

  init() {
    this.formatter.quill.root.addEventListener("click", this.onClick);
  }

  getTargetElement(): HTMLElement | null {
    return this.element;
  }

  onHide() {
    this.element = null;
  }

  onClick = (event: MouseEvent) => {
    const el = event.target;
    if (!(el instanceof HTMLElement) || el.tagName !== "VIDEO") {
      return;
    }

    // 防止选中视频时，自动播放
    event.preventDefault();
    this.element = el;
    this.formatter.show(this);
  };
}
