import { Navigate, useLocation } from "react-router-dom";
import { FORM_ERROR } from "final-form";
import { login } from "api/auth";
import { useIsAuthenticated, useSetAuth } from "states/auth/hooks";
import LoginForm from "./LoginForm";

// get string prop from object, otherwise default to undefined
const getStringProp = (obj: Record<string, unknown>, prop: string) => {
  return prop in obj && typeof obj[prop] === "string"
    ? (obj[prop] as string)
    : undefined;
};

const Login = () => {
  const setAuth = useSetAuth();
  const isAuthenticated = useIsAuthenticated();
  const location = useLocation();

  const submit = async (values: LoginFormValues) => {
    const { userName, password } = values;

    const { data, error } = await login(userName, password);

    if (error) {
      return { [FORM_ERROR]: error };
    }

    if (data) {
      setAuth({
        token: data.accessToken,
        accountId: data.userName,
      });
    }
  };

  if (isAuthenticated) {
    const from = location.state?.from as unknown;
    let pathname: string =
      typeof from === "string"
        ? from
        : typeof from === "object" && from != null && "pathname" in from
        ? getStringProp(from, "pathname") ?? "/"
        : "/";

    if (pathname === "/login") pathname = "/";

    return <Navigate to={{ pathname }} />;
  }

  return <LoginForm onSubmit={submit} />;
};

export default Login;
